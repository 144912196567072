import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: relative;
    size: 100%;
    font-family: Amiamie;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; 
    color: ${props =>
        props.variant === "green" ? "#092115":  
        props.variant === "white" ? "#FFFBF2":
        props.variant === "black" ? "#000000": 
        "transparent"};
`;

const TitleContainer = ({ type, variant, className, id,  children }) => {
  return (
    <Container
    type={type ? type : "title"} 
    variant={variant} 
    className={className ? `title-component ${className}`: "title-component"}
    id={id} 
    >
    {children}
    </Container> 
    );
}

export default TitleContainer;
