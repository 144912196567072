import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '../assets/svg/menu.svg';
import CloseIcon from '../assets/svg/croix.svg';

function DropdownMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const toggleMenu = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setIsTransitioning(false);
      setIsOpen(!isOpen);
    }, 300);
  };

  useEffect(() => {
    const body = document.querySelector('body');
    if (isOpen) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = ''; // Assurez-vous que le style de débordement est réinitialisé lorsque l'overlay est fermé
    }

    return () => {
      body.style.overflow = ''; // Assurez-vous que le style de débordement est réinitialisé lorsque le composant est démonté
    };
  }, [isOpen]);

  return (
    <div className="dropdown-wrapper">
      <img 
        className="dropdown-button" 
        src={isOpen ? CloseIcon : MenuIcon} 
        alt={isOpen ? "Close" : "Menu"} 
        onClick={toggleMenu} 
        style={{ transition: isTransitioning ? 'none' : 'transform 0.3s ease' }}
      />
      <div className={`dropdown-menu ${isOpen ? 'open' : ''}`}>
        {isOpen && (
          <img 
            className="close-button" 
            src={CloseIcon} 
            alt="Close" 
            onClick={toggleMenu} 
          />
        )}
        <ul>
          <li>
            <Link to="/About" onClick={toggleMenu}>
              About
            </Link>
          </li>
          <li>
            <Link to="/Contact" onClick={toggleMenu}>
              Contact
            </Link>
          </li>
          <li>
            <Link to="/OurProject" onClick={toggleMenu}>
              Our Project
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default DropdownMenu;
