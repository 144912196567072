import React from "react";
import styled from "styled-components";
import VerticalLine from "./VerticalLine";

const CenteredTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CenteredText = styled.span`
    color: #092115;
    font-family: Amiamie;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; 
    text-transform: capitalize;
    margin-right: 15px; /* Espacement entre le texte et la ligne */
`;

const TextCenter = ({ children, color, fontSize, fontWeight }) => {
  return (
    <CenteredTextContainer>
      <CenteredText color={color} fontSize={fontSize} fontWeight={fontWeight}>
        {children}
      </CenteredText>
      <VerticalLine />
    </CenteredTextContainer>
  );
};

export default TextCenter;
