import React from 'react'
import Gap from '../components/Gap.jsx'
import PaddingContainer from '../components/Container/PaddingContainer.jsx'
import TextContainer from '../components/Container/TextContainer.jsx'
import TopGap from '../components/TopGap.jsx'
import TitleContainer from '../components/Container/TitleContainer.jsx'
import { ColumnContainer2, OneThirdContainer, TwoThirdsContainer } from '../components/Container/ColumnContainer2.jsx';
import Button from '../components/Button.jsx'
import { Link } from 'react-router-dom'

function Startup() {
  return (
    <div>
      <div style={{ backgroundColor: "#DEFF00" }}>
        <TopGap />
        <PaddingContainer>
          <ColumnContainer2>
            <OneThirdContainer>
              <TitleContainer variant="green">Start-up </TitleContainer>
            </OneThirdContainer>
            <TwoThirdsContainer>
              <TextContainer variant="green">Conception pour Start-up</TextContainer>
              <Gap variant="25px" />
              <TextContainer variant="pgreen">Chez Harmonie Agency, nous comprenons l'importance d'une identité visuelle forte pour les start-up en plein essor. Notre service de contribution au design offre aux jeunes entreprises la possibilité de créer une marque mémorable qui attire l'attention et génère de l'engagement.</TextContainer>
              <Gap variant="25px" />
              <TextContainer variant="green">Notre Approche</TextContainer>
              <Gap variant="25px" />
              <TextContainer variant="pgreen">Nous travaillons en étroite collaboration avec les fondateurs pour comprendre leur vision et leurs objectifs commerciaux. Ensuite, nous créons une identité visuelle qui reflète leur mission et leur personnalité. Notre équipe de designers talentueux est là pour vous aider à créer une marque qui se démarque de la concurrence et qui attire votre public cible.</TextContainer>
              <ul>
                <li>
                  <TextContainer variant="pgreen">Consultation Personnalisée : Nous débutons par une consultation approfondie pour comprendre les valeurs, la vision et les objectifs de votre start-up. Nous croyons en une approche personnalisée pour chaque client, afin de garantir que nos services de design répondent parfaitement à vos besoins spécifiques.</TextContainer>
                </li>
                <Gap variant="15px" />
                <li>
                  <TextContainer variant="pgreen">Création d'Identité Visuelle : Notre équipe de designers talentueux travaille en étroite collaboration avec vous pour créer une identité visuelle distinctive qui capture l'essence de votre entreprise. Nous concevons des logos, des palettes de couleurs et des éléments graphiques qui reflètent votre marque et attirent votre public cible.</TextContainer>
                </li>
                <Gap variant="15px" />
                <li>
                  <TextContainer variant="pgreen">Design de Produits et Services : En plus de l'identité visuelle, nous offrons également des services de design de produits et de services. Que vous ayez besoin de concevoir une application mobile, un site web, ou d'autres supports numériques, nous sommes là pour vous aider à créer des expériences utilisateur exceptionnelles.</TextContainer>
                </li>
                <Gap variant="15px" />
                <li>
                  <TextContainer variant="pgreen">Test et Itérations : Nous croyons en un processus itératif de conception qui implique des tests et des ajustements continus pour garantir des résultats optimaux. Nous travaillons en étroite collaboration avec vous pour recueillir des retours d'information et apporter les modifications nécessaires tout au long du processus.</TextContainer>
                </li>
                <Gap variant="15px" />
                <li>
                  <TextContainer variant="pgreen">Partenariat à Long Terme : Notre objectif est de devenir un partenaire de confiance pour votre start-up à mesure qu'elle se développe. Nous sommes là pour soutenir votre croissance en vous fournissant des services de design de qualité et des conseils stratégiques tout au long de votre parcours.</TextContainer>
                </li>
              </ul>
          </TwoThirdsContainer>
        </ColumnContainer2> 
      </PaddingContainer>
      </div>
      <div style={{ backgroundColor: "#FFFBF2" }}>
      <PaddingContainer>
        <ColumnContainer2> 
        <OneThirdContainer>
          <TitleContainer variant="green">Contact</TitleContainer>
        </OneThirdContainer>
        <TwoThirdsContainer>
        <TextContainer variant="green">Prêt à donner vie à votre vision ?</TextContainer>
              <Gap variant="15px" />
              <TextContainer variant="pgreen">Contactez-nous dès aujourd'hui pour commencer à travailler sur le design de votre start-up et créer une marque qui se démarque.</TextContainer>
              <Gap variant="50px" />
              <Link to="/contact"><Button variant={"green"}>Contact</Button></Link>
        </TwoThirdsContainer>
        </ColumnContainer2> 
      </PaddingContainer>
      </div>
    </div>
  )
}

export default Startup;
