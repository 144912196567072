import React from 'react';
import '../Styles/Footer.css';
import '../Styles/Font.css';
import Logo from '../assets/svg/logo.svg';
import Behance from '../assets/svg/behance.svg';
import Facebook from '../assets/svg/facebook.svg';
import Twitter from '../assets/svg/twitter.svg';
import Instagram from '../assets/svg/instagram.svg';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="footer-container"> {/* Nouvelle div parente pour centrer le footer */}
      <footer>
        <div className="footer">
          <div className="align-3">
            <div className="align-1">
              <img className="logo" src={Logo} alt="Logo" style={{ filter: 'invert(1)' }} />
              <p>"Explorez l'innovation avec nous ! Suivez-nous sur les réseaux sociaux pour rester à l'affût des dernières tendances en matière de technologie et de design. Plongez dans notre univers digital où la créativité rencontre la performance." </p>
            </div>
            <div className="align-2">
              <div className="align-1">
                <h5>Platform</h5>
                <a href="Work.html" rel="noreferrer">Project</a>
                <Link to='/startup'>Startup</Link>
                <Link to='/freelance'>Freelance</Link>
              </div>
              <div className="align-1">
                <h5>Informations</h5>
                <Link to='/contact'>Contact</Link>
                <Link to='/a-propos'>À propos</Link>
              </div>
            </div>
            <div className="align-icon">
              <a rel="noreferrer" href="https://www.behance.net/Harmonie" target="_blank"><img id="behance" className="icons" src={Behance} alt="Behance" /></a>
              <a rel="noreferrer" href="https://www.facebook.com/share/DhpndKy68AkQfpbg/?mibextid=LQQJ4d" target="_blank"><img id="facebook" className="icons" src={Facebook} alt="Facebook" /></a>
              <a rel="noreferrer" href="https://twitter.com/HarmonieAgency" target="_blank"><img id="twitter" className="icons" src={Twitter} alt="Twitter" /></a>
              <a rel="noreferrer" href="https://www.instagram.com/harmonie.agency.paris/" target="_blank"><img id="instagram" className="icons" src={Instagram} alt="Instagram" /></a>
            </div>
          </div>
          <div className="copyrigth">
            <p>© Harmonie 2024 -<Link to="/mentions-légales">Mentions légales</Link>-<Link to="/politique-de-confidentialité">Politique de Confidentialité</Link></p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
