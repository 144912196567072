import React from 'react';
import { Link } from 'react-router-dom';
import '../Styles/Headermainsection2.css';
import icon from '../assets/svg/Arrow 1.svg';

function Headermainsection2() {
    return (
        <section className="section-2-landing">
            <div className="background-section">
                <div className="align-2">
                    <div className='block-1'>   
                    </div>
                    <div className='align-1 block-2'>
                        <h2 className="Text">Start-up</h2>
                        <div className="align-1 Text">
                            <p>Bienvenue dans le monde de l’innovation ! Chez Harmonie, nous comprenons les défis uniques des startups et sommes là pour vous aider à atteindre de nouveaux sommets numériques. Notre agence digitale dédiée collabore avec des entrepreneurs audacieux comme vous, en poussant des idées innovantes vers la réussite numérique.</p>
                        </div>
                        <p className="Text">Explorez le potentiel infini du numérique avec Harmonie. Prêt à créer quelque chose d’extraordinaire ensemble?</p>
                        <Link to="/startup" style={{ display: 'inline-flex', alignItems: 'center'}}>
                            <div className='link' style={{ display: 'flex', alignItems: 'center' }}>
                                <h3 className='Text'>Start-up section</h3>
                                <img src={icon} alt="icon" />
                            </div>
                        </Link> {/* Use Link here */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Headermainsection2;
