import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    size: 100%;
    flex: 1;
`;

const GroupContainer = ({ type, className, id,  children }) => {
  return (
    <Container
    type={type ? type : "group"} 
    className={className ? `group-component ${className}`: "group-component"}
    id={id} 
    >
    {children}
    </Container> 
    );
}

export default GroupContainer;
