import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-height: 100vh; /* Propriété maxHeight */

  /* Media queries pour les petits appareils (téléphones en mode portrait) */
  @media screen and (max-width: 479px) {
    max-height: none;
  }

  /* Media queries pour la réactivité */
  @media screen and (max-width: 767px) {
    max-height: none;
  }

  /* Media queries pour les appareils de taille moyenne (tablettes en mode paysage) */
  @media screen and (min-width: 480px) and (max-width: 767px) {
    max-height: none;
  }

  /* Media queries pour les tablettes */
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    max-height: none;
  }

  /* Media queries pour les grands écrans */
  @media screen and (min-width: 1024px) and (max-width: 1199px) {
    max-height: none;
  }

  /* Media queries pour les appareils extra-larges (ordinateurs de bureau) */
  @media screen and (min-width: 1200px) and (max-width: 1599px) {
    max-height: none;
  }

  /* Media queries pour les appareils extra-extra-larges (grands ordinateurs de bureau) */
  @media screen and (min-width: 1600px) and (max-width: 1999px) {
    max-height: 100vh;
  }

  /* Media queries pour les appareils extra-extra-larges (écrans extra-larges) */
  @media screen and (min-width: 2000px) {
    max-height: none;
  }
`;

class MaxHeightHeadermain extends React.Component {
  render() {
    const { maxHeight, children } = this.props;

    return <Container maxHeight={maxHeight}>{children}</Container>;
  }
}

export default MaxHeightHeadermain;
