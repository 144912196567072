import React, { useEffect } from "react";
import '../Styles/Faq.css';
import icon from '../assets/svg/ion_chevron-up-sharp.svg';
import PaddingContainer from "./Container/PaddingContainer";
import ColumnContainer from "./Container/ColumnContainer";

function Faq() {
    useEffect(() => {
        const questions = document.querySelectorAll(".question .align-1");

    function checkVisibility() {
        questions.forEach(function(question) {
            const answer = question.nextElementSibling;
            const rect = question.getBoundingClientRect();
            const arrowIcon = question.querySelector('.arrow-icon');

            if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
                if (!answer.classList.contains("opened")) {
                    answer.style.maxHeight = "0px";
                    answer.style.paddingBottom = "5px";
                    arrowIcon.classList.remove("rotated");
                }
            } else {
                if (answer.classList.contains("opened")) {
                    answer.classList.remove("opened");
                    answer.style.maxHeight = "0px";
                    arrowIcon.classList.remove("rotated");
                }
            }
        });
    }

    window.addEventListener("scroll", checkVisibility);
    window.addEventListener("resize", checkVisibility);

    checkVisibility();

    questions.forEach(function(question) {
        question.addEventListener("click", function() {
            const answer = this.nextElementSibling;
            const arrowIcon = this.querySelector('.arrow-icon');
            const isOpened = answer.classList.contains("opened");

            if (isOpened) {
                answer.classList.remove("opened");
                answer.style.maxHeight = "0px";
                arrowIcon.classList.remove("rotated");
                this.parentElement.classList.remove("opened"); // Supprime la classe "opened" du conteneur de la question
            } else {
                // Fermer toutes les autres réponses ouvertes
                questions.forEach(function(q) {
                    const a = q.nextElementSibling;
                    const ai = q.querySelector('.arrow-icon');
                    if (a !== answer && a.classList.contains("opened")) {
                        a.classList.remove("opened");
                        a.style.maxHeight = "0px";
                        ai.classList.remove("rotated");
                        q.parentElement.classList.remove("opened"); // Supprime la classe "opened" du conteneur de la question
                    }
                });

                answer.classList.add("opened");
                answer.style.maxHeight = answer.scrollHeight + "px";
                arrowIcon.classList.add("rotated");
                this.parentElement.classList.add("opened"); // Ajoute la classe "opened" au conteneur de la question
            }
        });
    });
});


    return (
        <div style={{ backgroundColor: "#FFFBF2" }}>
        <PaddingContainer>
            <ColumnContainer>
        <section className="section-6-landing">   
        <div className="faq">
                <h2 className="Text">FAQ</h2>
                <div className="question Text">
                    <div className="align-1">
                        <div className="toggle-icon">
                            <img src={icon} alt="Arrow Icon" className="arrow-icon"></img>
                        </div>
                        <h3>Quels services proposez-vous en tant qu’agence digitale ?</h3>
                    </div>
                    <div className="reponse">
                        <p>En tant qu’agence numérique polyvalente, nous nous spécialisons dans une gamme complète de services pour répondre à vos besoins numériques. Notre expertise englobe la conception Web, allant de la création de sites de vitrine élégants aux plateformes de commerce électronique complexes. Nous sommes également passionnés par la création et la modification de l’image de marque, vous aidant à développer une identité visuelle unique qui vous distingue dans votre industrie. Pour maximiser votre visibilité et votre impact en ligne, notre équipe excelle dans la mise en œuvre de stratégies de marketing numérique personnalisées. Cela comprend l’optimisation des moteurs de recherche (SEO), la gestion des médias sociaux, la publicité en ligne, et bien plus encore.</p>
                    </div>
                    <div className="border-line"></div>
                </div>
                <div className="question Text">
                    <div className="align-1">
                        <div className="toggle-icon">
                            <img src={icon} alt="Arrow Icon" className="arrow-icon"></img>
                        </div>
                        <h3>Comment puis-je demander un devis pour mes besoins spécifiques ?</h3>
                    </div>
                    <div className="reponse">
                        <p>Nous comprenons que chaque projet est unique, c’est pourquoi nous vous proposons un devis personnalisé adapté à vos besoins spécifiques. Pour commencer, il vous suffit de remplir notre formulaire de demande détaillé si dessus. Ce formulaire nous fournira les informations nécessaires pour comprendre la portée de votre projet et vos objectifs. Une fois que nous recevons votre demande, notre équipe dédiée prendra le temps d’examiner attentivement vos besoins. Vous pouvez vous attendre à ce que nous vous fassions parvenir rapidement une proposition sur mesure, comprenant une estimation détaillée des coûts et des délais. Nous sommes impatients de discuter de votre projet et de vous proposer la meilleure solution pour atteindre vos objectifs numériques.</p>
                    </div>
                </div>
                <div className="question Text">
                    <div className="align-1">
                        <div className="toggle-icon">
                            <img src={icon} alt="Arrow Icon" className="arrow-icon"></img>
                        </div>
                        <h3>Quel est le processus de travail typique lorsque j’utilise vos services?</h3>
                    </div>
                    <div className="reponse">
                        <p> Notre engagement envers votre succès commence par une consultation initiale approfondie. Nous croyons en une compréhension profonde de vos objectifs, de votre vision et des défis spécifiques auxquels vous faites face. Cette phase est cruciale pour nous permettre d’adapter notre approche à vos besoins uniques.
                            Suite à la consultation, notre équipe expérimentée se met au travail pour développer une stratégie personnalisée alignée sur vos objectifs. Cette stratégie servira de feuille de route tout au long du processus, intégrant des solutions innovantes pour maximiser votre impact numérique.
                            Une fois la stratégie solidifiée, nous commençons la phase de conception et de développement. Cette étape combine notre créativité et notre expertise technique de pointe pour donner vie à des solutions numériques exceptionnelles. Tout au long de ce processus, nous maintenons une transparence totale, en vous tenant informé à chaque étape cruciale.
                            Des points de validation réguliers sont intégrés pour s’assurer que le projet évolue selon vos attentes. Cette approche itérative nous permet d’ajuster rapidement notre orientation en fonction de vos commentaires, assurant un résultat final qui dépasse vos aspirations initiales. Notre objectif est de créer une collaboration transparente et harmonieuse, conduisant à des résultats concrets et à une satisfaction totale.</p>
                    </div>
                </div>
                <div className="question Text">
                    <div className="align-1">
                        <div className="toggle-icon">
                            <img src={icon} alt="Arrow Icon" className="arrow-icon"></img>
                        </div>
                        <h3>Combien de temps faut-il pour terminer un projet?</h3>
                    </div>
                    <div className="reponse">
                        <p>La durée d’un projet est étroitement liée à sa complexité et à ses exigences spécifiques. Chaque projet étant unique, il est difficile de fixer un délai standard. Cependant, soyez assuré que lors de notre phase de planification initiale, nous discuterons en détail des délais prévus pour votre projet particulier.
                            Cette discussion approfondie nous aidera à comprendre les délais critiques liés à votre entreprise et à vos objectifs. Nous travaillerons en étroite collaboration avec vous pour établir un calendrier réaliste qui garantit à la fois la qualité du travail et le respect des délais convenus. Notre objectif est de vous proposer une solution digitale exceptionnelle dans les délais qui correspondent à vos besoins spécifiques.</p>
                    </div>
                </div>
                <div className="question Text">
                    <div className="align-1">
                        <div className="toggle-icon">
                            <img src={icon} alt="Arrow Icon" className="arrow-icon"></img>
                        </div>
                        <h3>Quels sont vos tarifs pour les services offerts ?</h3>
                    </div>
                    <div className="reponse">
                        <p>Nos tarifs sont adaptés spécifiquement aux services demandés, en tenant compte de la nature unique de chaque projet. Pour des informations détaillées sur les prix et une estimation précise, nous vous encourageons à nous contacter directement. Vous pouvez également demander un devis en remplissant notre formulaire si dessus.
                            Cette approche personnalisée nous permet de bien comprendre vos besoins et de vous fournir une proposition de prix transparente et adaptée à la portée de votre projet. Notre équipe est prête à répondre à toutes vos questions et à discuter des détails spécifiques de votre collaboration. Nous sommes impatients de vous aider à atteindre vos objectifs numériques.</p>
                    </div>
                </div>
                <div className="question Text">
                    <div className="align-1">
                        <div className="toggle-icon">
                            <img src={icon} alt="Arrow Icon" className="arrow-icon"></img>
                        </div>
                        <h3>Quelles sont vos conditions de paiement ?</h3>
                    </div>
                    <div className="reponse">
                        <p> Nous comprenons que chaque projet a des exigences financières uniques, c’est pourquoi nous offrons des conditions de paiement flexibles qui s’adaptent à la spécificité de chaque collaboration. Lors de l’élaboration de votre proposition personnalisée, nous discuterons en détail des modalités de paiement spécifiques à votre projet.
                            Cette approche sur mesure nous permet de nous aligner sur vos besoins budgétaires tout en assurant la qualité et l’avancement constant du projet. Notre objectif est de créer une structure de paiement transparente et équitable qui facilite l’atteinte de vos objectifs tout en respectant vos contraintes financières. Nous sommes là pour répondre à toutes vos questions concernant les conditions de paiement et trouver la solution la plus adaptée à votre situation.</p>
                    </div>
                </div>
                <div className="question Text">
                    <div className="align-1">
                        <div className="toggle-icon">
                            <img src={icon} alt="Arrow Icon" className="arrow-icon"></img>
                        </div>
                        <h3>Que se passe-t-il après la livraison d’un projet ?</h3>
                    </div>
                    <div className="reponse">
                        <p>Notre engagement envers votre succès ne s’arrête pas à la livraison du projet. Après cette étape cruciale, nous offrons un soutien continu selon les termes de votre accord. Que vous ayez des questions, des préoccupations ou des problèmes, notre équipe dévouée est toujours à votre disposition pour vous fournir l’assistance nécessaire.
                            Nous comprenons que l’évolution de vos besoins et de l’environnement numérique peut nécessiter des ajustements ou des clarifications après la livraison. C’est pourquoi notre soutien ne se limite pas à la phase de développement initiale, mais s’étend au-delà pour assurer votre tranquillité d’esprit et la durabilité de votre solution numérique. N’hésitez pas à nous contacter à tout moment, nous sommes là pour vous accompagner à chaque étape de votre parcours digital.</p>
                    </div>
                </div>
            </div>
    </section>
    </ColumnContainer>
    </PaddingContainer>
    </div>
    )
}

export default Faq;