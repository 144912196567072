import React from 'react';

const ResizableImage = ({ src, alt }) => {
  const imageStyle = {
    height: '450px', // Hauteur fixe de l'image
    width: '100%', // Largeur de l'image à 100% du conteneur
    objectFit: 'cover', // Ajuste la taille de l'image tout en conservant les proportions et en remplissant le conteneur
  };

  return (
    <img src={src} alt={alt} style={imageStyle} />
  );
}

export default ResizableImage;
