import React from 'react';
import '../Styles/Home.css';
import PaddingContainer from '../components/Container/PaddingContainer.jsx';
import TextContainer from '../components/Container/TextContainer.jsx';
import TitleContainer from '../components/Container/TitleContainer.jsx';
import Gap from '../components/Gap.jsx';
import Formulaire from '../components/Formulaire.jsx';
import { ColumnContainer2, OneThirdContainer, TwoThirdsContainer } from '../components/Container/ColumnContainer2.jsx';
import ColumnContainer from '../components/Container/ColumnContainer.jsx';
import GroupContainer from '../components/Container/GroupContainer.jsx';
import TopGap from '../components/TopGap.jsx';


function Contact() {
  return (
    <div>
         <div style={{ backgroundColor: "#FFFBF2" }}>
            <TopGap/>
            <PaddingContainer>
                <ColumnContainer2>
                <OneThirdContainer>
                        <TitleContainer variant="green">Contact</TitleContainer>
                    </OneThirdContainer>
                    <TwoThirdsContainer>
                        <ColumnContainer>
                            <GroupContainer>
                                <TextContainer variant="green">Nous contacter</TextContainer>
                                <Gap variant="25px" />
                                <TextContainer variant="pgreen">Vous avez une question ou une demande de devis ? N'hésitez pas à nous contacter en remplissant le formulaire ci-dessous.</TextContainer>
                            </GroupContainer>
                            <GroupContainer>
                                <TextContainer variant="green">Informations de contact</TextContainer>
                                <Gap variant="25px" />
                                <TextContainer variant="pgreen">Harmonie Agency</TextContainer>
                                <TextContainer variant="pgreen">61 rue de Lyon, 75012 Paris</TextContainer>
                                <TextContainer variant="pgreen">Harmonieagency@outlook.com</TextContainer>
                                <TextContainer variant="pgreen"></TextContainer>
                            </GroupContainer>
                        </ColumnContainer>
                    </TwoThirdsContainer>
                </ColumnContainer2>
            </PaddingContainer>
        </div>
      <Formulaire />
    </div>
  );
}

export default Contact;

