import React from 'react'
import Gap from '../components/Gap.jsx'
import PaddingContainer from '../components/Container/PaddingContainer.jsx'
import TextContainer from '../components/Container/TextContainer.jsx'
import TopGap from '../components/TopGap.jsx'
import TitleContainer from '../components/Container/TitleContainer.jsx'

function Politiquedeconfidentialité() {
  return (
    <div style={{ backgroundColor: "#FFFBF2" }}>
      <PaddingContainer>
      <TopGap variant="100px"/>
      <TitleContainer variant="green">Politique de confidentialité</TitleContainer>
      <Gap variant="50px"/>
      <TextContainer variant="pgreen">Dernière mise à jour : 22/04/2024</TextContainer>
      <Gap variant="15px"/>
      <TextContainer variant="pgreen">La présente Politique de confidentialité décrit la manière dont Harmonie Agency collecte, utilise et protège les informations personnelles que vous fournissez sur le site https://harmonie-agency.com/. Nous nous engageons à protéger votre vie privée et à sécuriser les informations que vous nous fournissez.</TextContainer>
      <Gap variant="50px"/>
      <TextContainer variant="green">Collecte et utilisation des informations</TextContainer>
      <Gap variant="15px"/>
      <TextContainer variant="pgreen">Nous pouvons collecter des informations personnelles vous concernant lorsque vous visitez notre site, vous vous abonnez à notre newsletter, passez une commande, remplissez un formulaire ou interagissez avec notre site d'une autre manière. Les informations personnelles que nous collectons peuvent inclure votre nom, votre adresse e-mail, votre adresse postale, votre numéro de téléphone, vos informations de paiement et toute autre information que vous choisissez de nous fournir.</TextContainer>
      <Gap variant="50px"/>
      <TextContainer variant="green">Nous utilisons les informations que nous collectons pour :</TextContainer>
      <ul>
        <li>
            <TextContainer variant="pgreen">Personnaliser votre expérience et répondre à vos besoins individuels.</TextContainer>
        </li>
        <li>
            <TextContainer variant="pgreen">Améliorer notre site web et nos offres en fonction des retours que nous recevons.</TextContainer>
        </li>
        <li>
            <TextContainer variant="pgreen">Vous contacter par e-mail pour vous informer sur nos produits, services et promotions, si vous avez choisi de recevoir ces communications</TextContainer>
        </li>
        <li>
            <TextContainer variant="pgreen">Traiter les transactions que vous avez effectuées sur notre site.</TextContainer>
        </li>
        <li>
            <TextContainer variant="pgreen">Administrer un concours, une promotion, une enquête ou d'autres fonctionnalités du site.</TextContainer>
        </li>
      </ul>
      <Gap variant="50px"/>
      <TextContainer variant="green">Protection des informations</TextContainer>
      <Gap variant="15px"/>
      <TextContainer variant="pgreen">Nous mettons en œuvre diverses mesures de sécurité pour protéger vos informations personnelles lorsque vous passez une commande ou entrez, soumettez ou accédez à vos informations personnelles. Nous offrons l'utilisation d'un serveur sécurisé. Toutes les informations sensibles/transmises sont cryptées et sont accessibles uniquement par un nombre limité de personnes ayant des droits d'accès spéciaux à ces systèmes, et sont tenues de conserver la confidentialité de ces informations.</TextContainer>
      <Gap variant="50px"/>
      <TextContainer variant="green">Divulgation à des tiers</TextContainer>
      <Gap variant="15px"/>
      <TextContainer variant="pgreen">Nous ne vendons, n'échangeons ni ne transférons vos informations personnelles identifiables à des tiers sans votre consentement, sauf dans les cas suivants :</TextContainer>
      <ul>
        <li>
            <TextContainer variant="pgreen">Lorsque cela est nécessaire pour répondre à une demande que vous avez faite pour nos produits ou services</TextContainer>
        </li>
        <li>
            <TextContainer variant="pgreen">Lorsque la divulgation est requise par la loi, pour appliquer nos politiques de site, ou pour protéger nos droits, notre propriété ou notre sécurité, ou ceux des autres.</TextContainer>
        </li>
      </ul>
      <Gap variant="50px"/>
      <TextContainer variant="green">Consentement</TextContainer>
      <Gap variant="15px"/>
      <TextContainer variant="pgreen">En utilisant notre site, vous consentez à notre politique de confidentialité en ligne.</TextContainer>
      <Gap variant="50px"/>
      <TextContainer variant="green">Modifications de la politique de confidentialité</TextContainer>
      <Gap variant="15px"/>
      <TextContainer variant="pgreen">Si nous décidons de modifier notre politique de confidentialité, nous publierons ces changements sur cette page.</TextContainer>
      <Gap variant="15px"/>
      <TextContainer variant="pgreen">Si vous avez des questions concernant cette politique de confidentialité, vous pouvez nous contacter à l'adresse suivante : Harmonieagency@outlook.com</TextContainer>
      </PaddingContainer>
    </div>
  )
}

export default Politiquedeconfidentialité



