import React from 'react';
import Headermain from '../components/Headermain';
import Headermainsection1 from '../components/Headermainsection1';
import Headermainsection2 from '../components/Headermainsection2';
import Headermainsection3 from '../components/Headermainsection3';
import Formulaire from '../components/Formulaire';
import Faq from '../components/Faq';
import '../Styles/Home.css';



function Home() {
  return (
    <div style={{ backgroundColor: "#FFFBF2" }}>
      <Headermain />
      <Headermainsection1 />
      <Headermainsection2 />
      <Headermainsection3 />
      <Formulaire />
      <Faq />
    </div>
  );
}

export default Home;

