import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 13%;

  /* Media queries for small devices (portrait phones) */
  @media screen and (max-width: 479px) {
    padding: ${props => props.padding || '12%'};
    ${props => props.variant === "leftright" && `
      padding: 0% 12% ;
    `}
    ${props => props.variant === "top" && `
      padding: 35% 0% 0% 0%;
    `}
    ${props => props.variant === "bottom" && `
      padding: 0% 0% 20% 0%;
    `}

  }

  /* Media queries for responsiveness */
  @media screen and (max-width: 767px) {
    padding: ${props => props.padding || '12%'};
    ${props => props.variant === "leftright" && `
      padding: 0% 12% ;
    `}
    ${props => props.variant === "top" && `
      padding: 30% 0% 0% 0%;
    `}
    ${props => props.variant === "bottom" && `
      padding: 0% 0% 30% 0%;
    `}
  }

  /* Media queries for medium devices (landscape tablets) */
  @media screen and (min-width: 480px) and (max-width: 767px) {
    padding: ${props => props.padding || '12%'};
    ${props => props.variant === "leftright" && `
      padding: 0% 12% ;
    `}
    ${props => props.variant === "top" && `
      padding: 16% 0% 0% 0%;
    `}
    ${props => props.variant === "bottom" && `
      padding: 0% 0% 16% 0%;
    `}
  }

  /* Media queries for tablets */
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    padding: ${props => props.padding || '13%'};
    ${props => props.variant === "leftright" && `
      padding: 0% 13% ;
    `}
    ${props => props.variant === "top" && `
      padding: 11% 0% 0% 0%;
    `}
    ${props => props.variant === "bottom" && `
      padding: 0% 0% 11% 0%;
    `}
  }

  /* Media queries for large screens */
  @media screen and (min-width: 1024px) and (max-width: 1199px) {
    padding: ${props => props.padding || '13%'};
    ${props => props.variant === "leftright" && `
      padding: 0% 13% ;
    `}
    ${props => props.variant === "top" && `
      padding: 10% 0% 0% 0%;
    `}
    ${props => props.variant === "bottom" && `
      padding: 0% 0% 10% 0%;
    `}
  }

  /* Media queries for extra large devices (desktops) */
  @media screen and (min-width: 1200px) and (max-width: 1599px) {
    padding: ${props => props.padding || '10% 13%'};
    ${props => props.variant === "leftright" && `
      padding: 0% 13% ;
    `}
    ${props => props.variant === "top" && `
      padding: 12% 0% 0% 0%;
    `}
    ${props => props.variant === "bottom" && `
      padding: 0% 0% 12% 0%;
    `}
    
  }

  /* Media queries for extra extra large devices (large desktops) */
  @media screen and (min-width: 1600px) and (max-width: 1999px) {
    padding: ${props => props.padding || '9% 13%'};
    ${props => props.variant === "leftright" && `
      padding: 0% 13%;
    `}
    ${props => props.variant === "top" && `
      padding: 10% 0% 0% 0%;
    `}
    ${props => props.variant === "bottom" && `
      padding: 0% 0% 10% 0%;
    `}
  }

  /* Media queries for extra extra large devices (extra large desktops) */
  @media screen and (min-width: 2000px) {
    padding: ${props => props.padding || '7% 13%'};
    ${props => props.variant === "leftright" && `
      padding: 0% 13% ;
    `}
    ${props => props.variant === "top" && `
      padding: 7% 0% 0% 0%;
    `}
    ${props => props.variant === "bottom" && `
      padding: 0% 0% 7% 0%;
    `}
  }
`;

class PaddingContainer extends React.Component {
  render() {
    const { padding, variant, children } = this.props;

    return <Container padding={padding} variant={variant}>{children}</Container>;
  }
}

export default PaddingContainer;
