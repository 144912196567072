import React from 'react'
import PaddingContainer from './Container/PaddingContainer.jsx';
import ColumnContainer from './Container/ColumnContainer.jsx';
import TitleContainer from './Container/TitleContainer.jsx';
import TextContainer from './Container/TextContainer.jsx';
import Gap from './Gap.jsx';
import GroupContainer from './Container/GroupContainer.jsx';

function Headermainsection3() {
  return (
    <div style={{ backgroundColor: "#FFFBF2" }}>
        <PaddingContainer>
            <ColumnContainer >
                <GroupContainer>
                    <TitleContainer variant="green">Services</TitleContainer>
                </GroupContainer>
                <GroupContainer>
                    <TextContainer variant="green">Renforcez votre identité d’entreprise avec notre expertise en image de marque</TextContainer>
                    <Gap variant="25px"/>
                    <TextContainer variant="pgreen">Dans notre agence, nous avons une équipe dédiée qui excelle dans la création et la gestion de marques. Du développement méticuleux de votre logo à l’élaboration stratégique d’une stratégie de marque cohérente, nous nous engageons à façonner une identité mémorable pour votre entreprise. Notre objectif est de communiquer efficacement vos valeurs à votre public cible, en assurant une impression durable et positive.</TextContainer>
                    <Gap variant="15px"/>
                    <TextContainer variant="pseo"><h1>identité de marque, conception de logo, positionnement stratégique de marque, présence en ligne, autorité de marque.</h1></TextContainer>
                    <Gap variant="40px"/>
                    <TextContainer variant="green">Donnez vie à vos idées avec notre expertise en conception numérique.</TextContainer>
                    <Gap variant="25px"/>
                    <TextContainer variant="pgreen">De la conception graphique à la création de contenu visuel percutant, notre équipe de conception est passionnée par la concrétisation de votre vision. Qu’il s’agisse de concevoir des graphiques pour les médias sociaux, de créer des visuels pour des campagnes publicitaires ou de produire des documents convaincants pour l’impression, nous exploitons une créativité numérique exceptionnelle. Notre objectif est non seulement de répondre à vos attentes, mais de les dépasser, en créant des designs qui résonnent et laissent une impression durable.</TextContainer>
                    <Gap variant="15px"/>
                    <TextContainer variant="pseo"><h1>conception numérique, services de conception graphique, médias sociaux, publicité, documents imprimés, découvrabilité en ligne, contenu visuel.</h1></TextContainer>
                </GroupContainer>
                <GroupContainer>
                    <TextContainer variant="green">Conception Web intuitive et esthétique pour l’expérience utilisateur</TextContainer>
                    <Gap variant="25px"/>
                    <TextContainer variant="pgreen">L’interface utilisateur (UI) et l’expérience utilisateur (UX) sont au cœur de chaque projet que nous entreprenons. Notre approche consiste à concevoir des sites Web qui non seulement captivent par leur esthétique, mais offrent également une expérience utilisateur intuitive et transparente. Grâce à un examen attentif des interactions avec les utilisateurs, nous nous assurons que votre site Web est non seulement fantastique, mais fonctionne également parfaitement, ce qui entraîne un voyage satisfaisant pour vos visiteurs.</TextContainer>
                    <Gap variant="15px"/>
                    <TextContainer variant="pseo"><h1>services de conception Web, conception UI / UX, sites Web visuellement attrayants, expérience conviviale, performance SEO.</h1></TextContainer>
                    <Gap variant="40px"/>
                    <TextContainer variant="green">Améliorez votre présence en ligne grâce à la communication numérique stratégique.</TextContainer>
                    <Gap variant="25px"/>
                    <TextContainer variant="pgreen">Spécialisé dans la mise en œuvre de stratégies de communication numérique efficaces, nous sommes votre partenaire dans la navigation dans le paysage dynamique en ligne. De la gestion habile des plateformes de médias sociaux à la création de contenu engageant, nous travaillons pour maximiser votre visibilité en ligne. Notre approche stratégique vise à renforcer votre réputation en ligne, à favoriser des interactions significatives avec votre public et, en fin de compte, à stimuler votre succès numérique.</TextContainer>
                    <Gap variant="15px"/>
                    <TextContainer variant="pseo"><h1>communication digitale, gestion des réseaux sociaux, création de contenu, visibilité en ligne, référencement, stratégie SEO.</h1></TextContainer>
                </GroupContainer>
            </ColumnContainer >
        </PaddingContainer>
    </div>
  )
}

export default Headermainsection3
