import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Mentionslégales from './pages/Mentions-légales';
import Startup from './pages/Startup';
import Freelance from './pages/Freelance';
import Contact from './pages/Contact';
import Projets from './pages/Projets';
import Apropos from './pages/A propos';
import Politiquedeconfidentialité from './pages/Politique-de-confidentialité';
import Zephyr from './pages/Zephyr';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route index element={<Home />} />
        <Route path='/a-propos' element={<Apropos />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/projets' element={<Projets/>} />
        <Route path='/projets/zephyr' element={<Zephyr/>} />
        <Route path='/mentions-légales' element={<Mentionslégales />} />
        <Route path='/politique-de-confidentialité' element={<Politiquedeconfidentialité />} />
        <Route path='/startup' element={<Startup />} />
        <Route path='/freelance' element={<Freelance/>} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
