import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../Styles/Navbar.css';
import '../Styles/Font.css';
import Logo from '../assets/svg/logo.svg';
import DropdownMenu from './NavBar-button';
import TextContainer from './Container/TextContainer';



function Navbar() {
  const [, setIsNavBarRetracted] = useState(false);

  useEffect(() => {
    let lastScrollTop = 0;

    function handleScroll() {
      const currentScrollPos = window.pageYOffset;
      const navBar = document.querySelector('.navbar-background');

      if (currentScrollPos > lastScrollTop) {
        // Scrolling down
        navBar.style.top = '-100px';
        navBar.style.transition = 'top 0.6s ease';
        setIsNavBarRetracted(true); // Set state to indicate that navbar is retracted
      } else {
        // Scrolling up
        navBar.style.top = '0';
        navBar.style.transition = 'top 0.6s ease';
        setIsNavBarRetracted(false); // Set state to indicate that navbar is not retracted
      }
      lastScrollTop = currentScrollPos;
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="navbar-background" style={{ position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 1000 }}>
      <div className="navbar-container">
        <ul className="navbar-logo" id="navbarMenu">
          <li className="navbar-brand">
            <Link to='/'>
              <img className="logo" src={Logo} alt="Logo" />
            </Link>
          </li>
        </ul>
        <DropdownMenu />

        <div className="link-group">
          <li>

          </li>
          <li>
            <Link to='/a-propos'><TextContainer variant="pgreen">À Propos</TextContainer></Link>
          </li>
          <li>
            <Link to='/Contact'><TextContainer variant="pgreen">Contact</TextContainer></Link>
          </li>
          <li>
            <Link to='/projets'><TextContainer variant="pgreen">Nos Projets</TextContainer></Link>
          </li>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
