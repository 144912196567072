import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: 50px;
  max-width: 1200px;
  margin: 0 auto; 
  flex-direction: none;

  /* Media queries for small devices (phones) */
  @media screen and (max-width: 479px) {
    gap: 20px;
    flex-direction: column;
  }

  /* Media queries for medium devices (tablets) */
  @media screen and (min-width: 480px) and (max-width: 767px) {
    gap: 2Opx;
    flex-direction: column;
  }


  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 30px;
  }
  
  /* Media queries for medium devices (landscape tablets) */
  @media screen and (min-width: 480px) and (max-width: 767px) {
    flex-direction: column;
    gap: 30px;
    width: 100%;
  }
  
  
  /* Media queries for tablets */
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    gap: 40px;
  }
`;

const OneContainer = styled.div`
    width: 'calc(20% - 25px)',
`;

const TwoContainer = styled.div`
    width: 'calc(80% - 25px)',

  /* Media queries for small devices (phones) */
  @media screen and (max-width: 479px) {
    gap: 20px;
    flex-direction: column;
    width: 100%;
  }

  /* Media queries for medium devices (tablets) */
  @media screen and (min-width: 480px) and (max-width: 767px) {
    gap: 2Opx;
    flex-direction: column;
    width: 100%;
  }


  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 30px;
    width: 100%;
  }
  
  /* Media queries for medium devices (landscape tablets) */
  @media screen and (min-width: 480px) and (max-width: 767px) {
    flex-direction: column;
    gap: 30px;
    width: 100%;
  }
  
  
  /* Media queries for tablets */
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    flex-direction: column;
    gap: 40px;
  }
`;

const ColumnContainer3 = ({ children }) => {
  return <Container>{children}</Container>;
};

export { ColumnContainer3, OneContainer, TwoContainer };
