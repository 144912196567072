import React from "react";
import styled from "styled-components";

const VerticalLineComponent = styled.div`
  width: 1px;
  height: 100%;
  background-color: #092115;
`;

const VerticalLine = () => {
  return <VerticalLineComponent />;
};

export default VerticalLine;
