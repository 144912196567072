import React, { useState, useEffect } from 'react';
import PaddingContainer from './Container/PaddingContainer.jsx';
import Button from './Button.jsx';
import InputForms from './InputForm.jsx';
import TitleContainer from './Container/TitleContainer.jsx';
import ColumnContainer from './Container/ColumnContainer.jsx';
import GroupContainer from './Container/GroupContainer.jsx';
import Gap from './Gap.jsx';
import { ColumnContainer2, OneThirdContainer, TwoThirdsContainer } from './Container/ColumnContainer2.jsx';
import Checkbox from './Checkbox.jsx';
import { Link } from 'react-router-dom';

function Formulaire() {
  const [name, setName] = useState(localStorage.getItem('name') || '');
  const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem('phoneNumber') || '');
  const [budget, setBudget] = useState(localStorage.getItem('budget') || '');
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [country, setCountry] = useState(localStorage.getItem('country') || '');
  const [description, setDescription] = useState(localStorage.getItem('description') || '');
  const [enterprise, setEnterprise] = useState(localStorage.getItem('enterprise') || '');
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(localStorage.getItem('privacyPolicyChecked') === 'true' || false);
  const [newsletterChecked, setNewsletterChecked] = useState(localStorage.getItem('newsletterChecked') === 'true' || false);
  const [validationError, setValidationError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [cooldown, setCooldown] = useState(0); // Temps de refroidissement initialisé à 0

  useEffect(() => {
    localStorage.setItem('name', name);
    localStorage.setItem('phoneNumber', phoneNumber);
    localStorage.setItem('budget', budget);
    localStorage.setItem('email', email);
    localStorage.setItem('country', country);
    localStorage.setItem('description', description);
    localStorage.setItem('enterprise', enterprise);
    localStorage.setItem('privacyPolicyChecked', privacyPolicyChecked);
    localStorage.setItem('newsletterChecked', newsletterChecked);
  }, [name, phoneNumber, budget, email, country, description, enterprise, privacyPolicyChecked, newsletterChecked]);

  useEffect(() => {
    let intervalId;
    if (cooldown > 0) {
      intervalId = setInterval(() => {
        setCooldown(prevCooldown => prevCooldown - 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [cooldown]);

  const handleButtonClick = () => {
    console.log('Button clicked');

    if (!name || !phoneNumber || !budget || !email || !country || !description || !privacyPolicyChecked) {
      // Validation Error
      setValidationError(true);
      setTimeout(() => {
        setValidationError(false);
      }, 3000);
    } else {
      if (cooldown > 0) {
        console.log('Please wait before submitting again');
        return;
      }

      const privacy = privacyPolicyChecked ? 'oui' : 'non';
      const newsletter = newsletterChecked ? 'oui' : 'non';
      const formData = new FormData(); // Créer un nouvel objet FormData
      formData.append('name', name);
      formData.append('phoneNumber', phoneNumber);
      formData.append('budget', budget);
      formData.append('email', email);
      formData.append('country', country);
      formData.append('description', description);
      formData.append('enterprise', enterprise);
      formData.append('privacyPolicyChecked', privacy); // Ajouter le champ privacyPolicyChecked
      formData.append('newsletterChecked', newsletter); // Ajouter le champ newsletterChecked

      // Envoyer les données du formulaire avec fetch
      fetch('form-submit.php', {
        method: 'POST',
        body: formData // Utiliser formData comme corps de la requête
      })
        .then(response => {
          if (response.ok) {
            // Réinitialiser les champs et les états après soumission réussie
            setFormSubmitted(true);
            setName('');
            setPhoneNumber('');
            setBudget('');
            setEmail('');
            setCountry('');
            setDescription('');
            setEnterprise('');
            setPrivacyPolicyChecked(false);
            setNewsletterChecked(false);
            localStorage.clear(); // Effacer les données locales
            setCooldown(60); // Définir un délai de refroidissement de 60 secondes
          } else {
            throw new Error('Erreur lors de la soumission du formulaire');
          }
        })
        .catch(error => {
          console.error(error.message);
          // Gérer les erreurs ici
        });
    }
  };

  return (
    <div style={{ backgroundColor: '#092115' }}>
      <PaddingContainer>
        <ColumnContainer2 variant="one-third-two-thirds">
          <OneThirdContainer>
            <GroupContainer>
              <TitleContainer variant="white">Projet</TitleContainer>
            </GroupContainer>
          </OneThirdContainer>
          <TwoThirdsContainer>
            <GroupContainer>
              <ColumnContainer>
                <GroupContainer>
                  <InputForms
                    label="Name"
                    id="name"
                    placeholder="Nom"
                    type="Nom"
                    isRequired
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={!name && validationError ? 'error' : ''}
                    style={!name && validationError ? { backgroundColor: 'rgba(255, 0, 0, 0.3)', animation: `shake 0.5s` } : {}}
                  />
                  <Gap variant="50px" />
                  <InputForms
                    label="Phone-number"
                    id="Phone-number"
                    placeholder="Téléphone"
                    type="Téléphone"
                    isRequired
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className={!phoneNumber && validationError ? 'error' : ''}
                    style={!phoneNumber && validationError ? { backgroundColor: 'rgba(255, 0, 0, 0.3)', animation: `shake 0.5s` } : {}}
                  />
                  <Gap variant="50px" />
                  <InputForms
                    label="Budget"
                    id="Budget"
                    placeholder="Budget"
                    type="Budget"
                    isRequired
                    value={budget}
                    onChange={(e) => setBudget(e.target.value)}
                    className={!budget && validationError ? 'error' : ''}
                    style={!budget && validationError ? { backgroundColor: 'rgba(255, 0, 0, 0.3)', animation: `shake 0.5s` } : {}}
                  />
                </GroupContainer>
                <GroupContainer>
                  <InputForms
                    label="Entreprise"
                    id="Enterprise"
                    placeholder="Entreprise"
                    type="text"
                    value={enterprise}
                    onChange={(e) => setEnterprise(e.target.value)}
                  />
                  <Gap variant="50px" />
                  <InputForms
                    label="Email"
                    id="Email"
                    placeholder="Email"
                    type="Email"
                    isRequired
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={!email && validationError ? 'error' : ''}
                    style={!email && validationError ? { backgroundColor: 'rgba(255, 0, 0, 0.3)', animation: `shake 0.5s` } : {}}
                  />
                  <Gap variant="50px" />
                  <InputForms
                    label="Country"
                    id="Country"
                    placeholder="Pays"
                    type="Pays"
                    isRequired
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    className={!country && validationError ? 'error' : ''}
                    style={!country && validationError ? { backgroundColor: 'rgba(255, 0, 0, 0.3)', animation: `shake 0.5s` } : {}}
                  />
                </GroupContainer>
              </ColumnContainer>
              <Gap variant="50px" />
              <GroupContainer>
                <InputForms
                  label="Description"
                  id="Description"
                  placeholder="Description"
                  type="Description"
                  isRequired
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className={!description && validationError ? 'error' : ''}
                  style={!description && validationError ? { backgroundColor: 'rgba(255, 0, 0, 0.3)', animation: `shake 0.5s` } : {}}
                />
                <Gap variant="50px" />
                <Checkbox
                  label={(
                    <>
                      <Link to='/politique-de-confidentialité' style={{ color: '#FFFBF2'}}>
                      J'ai pris connaissance de la politique de confidentialité d'Harmonie Agency*.
                      </Link>
                    </>
                  )}
                  isRequired
                  checked={privacyPolicyChecked}
                  onChange={(e) => setPrivacyPolicyChecked(e.target.checked)}
                  className={!privacyPolicyChecked && validationError ? 'error' : ''}
                  validationError={!privacyPolicyChecked && validationError}
                />
                <Gap variant="25px" />
                <Checkbox
                  label="S'abonner à la newsletter"
                  checked={newsletterChecked}
                  onChange={(e) => setNewsletterChecked(e.target.checked)}
                />
                <Gap variant="50px" />
                <Button variant="white" onClick={handleButtonClick}>
                  {cooldown > 0 ? `Envoyer (${cooldown}s)` : "Envoyer"}
                </Button>
                {validationError && (
                  <div>
                    <Gap variant="25px" />
                    <p className="error-text" style={{ color: 'red' }}>Veuillez remplir tous les champs obligatoires</p>
                  </div>
                )}
                {formSubmitted && (
                  <div>
                    <Gap variant="25px" />
                    <p className="success-text" style={{ color: '#FFFBF2' }}>Formulaire envoyé avec succès</p>
                  </div>
                )}
              </GroupContainer>
            </GroupContainer>
          </TwoThirdsContainer>
        </ColumnContainer2>
      </PaddingContainer>
      <style>{`
        @keyframes shake {
          0% { transform: translateX(0); }
          25% { transform: translateX(-5px); }
          50% { transform: translateX(5px); }
          75% { transform: translateX(-5px); }
          100% { transform: translateX(0); }
        }
      `}</style>
    </div>
  );
}

export default Formulaire;
