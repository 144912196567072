import React from 'react';
import styled from 'styled-components';
import icon from '../assets/svg/check.svg';

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  color: white;
  font-family: BD;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  ${({ validationError }) => validationError && `
    color: red; /* Ajoutez les styles rouges en cas d'erreur */
  `}
`;

const CheckboxInput = styled.input`
  display: none;
`;

const Icon = styled.img`
  transition: 0.3s ease-in-out;
`;

const Text = styled.span`
`;

const Link = styled.a `
    color: #FFFBF2 !important;
    text-decoration-color: #FFFBF2 !important;
    cursor: pointer;
    text-decoration: none !important; /* Annule la soulignement */
    ${({ validationError }) => validationError && `
    color: red; /* Ajoutez les styles rouges en cas d'erreur */
  `}
    
`;

const StyledCheckbox = styled.div`
  border: 1px solid ${(props) => (props.checked ? 'transparent' : '#FFFBF2')};
  appearance: none;
  width: 1.1em; /* Utilisation d'une valeur en em pour la largeur */
  height: 1.1em;
  min-width: 1.1em;
  padding: 0px;
  background-color: transparent;
  cursor: pointer;
  margin-right: 10px;
  position: relative;
  transition: border-color 0.3s ease-in-out;
  ${({ validationError }) => validationError && `
    border-color: red; /* Ajoutez les styles rouges en cas d'erreur */
    transition: none;
  `}
`;

const Checkbox = ({ label, checked, onChange, validationError }) => {

  const linkStyle = { color: '#FFFBF2', textDecoration: 'none' };

  const processLabel = () => {
    if (typeof label === 'string') {
      const parts = label.split(/(<a.*?<\/a>)/);
      return parts.map((part, index) => {
        if (part.startsWith('<a')) {
          return <Link key={index} href="#" style={linkStyle} dangerouslySetInnerHTML={{ __html: part }} />;
        } else {
          return <Text key={index}>{part}</Text>;
        }
      });
    } else {
      return label;
    }
  };

  return (
    <CheckboxContainer validationError={validationError}>
      <CheckboxInput type="checkbox" checked={checked} onChange={onChange} />
      <StyledCheckbox checked={checked} validationError={validationError}>
        {checked && <Icon src={icon} alt="Icon" />}
      </StyledCheckbox>
      {processLabel()} {/* Affichez le texte à côté de la checkbox */}
    </CheckboxContainer>
    
  );
};

export default Checkbox;
