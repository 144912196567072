import React, { useState } from 'react';
import styled, { css } from 'styled-components';

// Styles du conteneur de l'input et du texte explicatif
const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  margin-bottom: 5px;
  font-size: 16px;
  font-family: BD;
  color: #FFFBF2; 
  ${({ variant }) => variant === 'green' && css`
    color: #092115; /* Couleur du texte en vert */
  `}
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  font-family: BD;
  background-color: transparent; 
  color: #FFFBF2;
  outline: none;
  resize: none;
  ${({ variant }) => variant === 'green' && css`
    color: #092115;
  `}
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  font-family: BD;
  background-color: transparent; 
  color: #FFFBF2; 
  outline: none;
  resize: none;
  ${({ variant }) => variant === 'green' && css`
    color: #092115;
    
  `}
`;

// Composant Input
const InputForms = ({ isRequired, label, type, placeholder, variant, ...rest }) => {
  const [isValidInput, setIsValidInput] = useState(true);

  // Fonction de validation pour le numéro de téléphone
  const validatePhoneNumber = (value) => {
    const regex = /^\d+$/;
    return regex.test(value);
  };

  // Fonction de validation pour le budget
  const validateBudget = (value) => {
    const regex = /^\d+(\.\d)?$/; // Accepte les nombres décimaux jusqu'à deux décimales
    return regex.test(value);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    // Vérifie le type de champ et valide en conséquence
    switch (label) {
      case 'Phone number':
        setIsValidInput(validatePhoneNumber(value));
        break;
      case 'Budget':
        setIsValidInput(validateBudget(value));
        break;
      default:
        setIsValidInput(true);
        break;
    }
    // Autres manipulations d'état si nécessaire
  };

  const labelText = isRequired ? `${placeholder} *` : label;

  // Rendre un textarea si le label est "Description"
  const InputComponent = label === 'Description' ? StyledTextarea : StyledInput;

  return (
    <Container>
      <Label variant={variant}>{labelText}</Label>
      <InputComponent
        type={type}
        placeholder={placeholder}
        onChange={handleInputChange}
        variant={variant}
        {...rest}
      />
      {/* Afficher un message d'erreur si le format de l'entrée est invalide */}
      {!isValidInput && <span style={{ color: 'red' }}>Invalid input</span>}
    </Container>
  );
};

export default InputForms;
