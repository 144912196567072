import React from 'react'
import PaddingContainer from './Container/PaddingContainer.jsx';
import Button from './Button.jsx';
import ColumnContainer from './Container/ColumnContainer.jsx';
import TitleContainer from './Container/TitleContainer.jsx';
import TextContainer from './Container/TextContainer.jsx';
import Gap from './Gap.jsx';
import GroupContainer from './Container/GroupContainer.jsx';
import { Link } from 'react-router-dom';

function Headermainsection1() {
  return (
    <div style={{ backgroundColor: '#092115' }}>
    <PaddingContainer >
        <ColumnContainer >
            <GroupContainer>
                <TitleContainer variant="white">L'agence</TitleContainer>
            </GroupContainer>
            <GroupContainer>
                <TextContainer variant="white">Notre mission</TextContainer>
                <Gap variant="25px"/>
                <TextContainer variant="pwhite">Transformer les visions en succès numérique. Nous collaborons avec passion avec nos clients pour concevoir des solutions sur mesure, de la conception Web à la stratégie de marketing numérique.</TextContainer>
                <Gap variant="15px"/>
                <TextContainer variant="pseo"><h1>identité de marque, conception de logo, positionnement stratégique de marque, présence en ligne, autorité de marque.</h1></TextContainer>
                <Gap variant="40px"/>
                <TextContainer variant="white">Notre approche</TextContainer>
                <Gap variant="25px"/>
                <TextContainer variant="pwhite">L’innovation est au cœur de tout ce que nous faisons. Nous adoptons les dernières technologies et les meilleures pratiques pour propulser nos clients vers de nouveaux sommets dans le monde numérique.</TextContainer>
                <Gap variant="15px"/>
                <TextContainer variant="pseo"><h1>SEO, visibilité en ligne, classement, stratégie de référencement, mots-clés, contenu optimisé, audit SEO,Analyse concurrentielle</h1></TextContainer>
            </GroupContainer>    
            <GroupContainer>
                <TextContainer variant="white">Pourquoi nous choisir?</TextContainer>
                <Gap variant="25px"/>
                <TextContainer variant="pwhite">Chez Harmonie, nous sommes bien plus qu’une agence numérique. Nous sommes votre partenaire de confiance dans le monde numérique. Notre équipe dédiée met son expertise au service de chaque projet, garantissant des résultats mesurables et une approche collaborative. Que vous soyez une start-up ambitieuse, une PME en croissance ou une entreprise établie, notre engagement envers votre réussite numérique reste constant. Découvrez comment Harmonie peut concrétiser vos ambitions numériques.</TextContainer>
                <Gap variant="15px"/>
                <TextContainer variant="pseo"><h1>agence numérique de confiance, expertise dédiée, résultats mesurables, approche collaborative, ambitions numériques, réussite en ligne, solutions personnalisées, stratégie numérique efficace, innovation numérique.</h1></TextContainer>
                <Gap variant="40px"/>
                <Link to="/contact"><Button variant="white-border">Contact</Button></Link>
            </GroupContainer>
        </ColumnContainer>
    </PaddingContainer>
    </div>
  )
}

export default Headermainsection1
