import React from 'react'
import Gap from '../components/Gap.jsx'
import PaddingContainer from '../components/Container/PaddingContainer.jsx'
import TextContainer from '../components/Container/TextContainer.jsx'
import TopGap from '../components/TopGap.jsx'
import TitleContainer from '../components/Container/TitleContainer.jsx'
import { ColumnContainer2, OneThirdContainer, TwoThirdsContainer } from '../components/Container/ColumnContainer2.jsx';
import { ColumnContainer3, OneContainer, TwoContainer } from '../components/Container/ColumnContainer3.jsx';
import Button from '../components/Button.jsx'
import { Link } from 'react-router-dom'
import GroupContainer  from '../components/Container/GroupContainer.jsx'
import ColumnContainer  from '../components/Container/ColumnContainer.jsx'
import ImageGallery from '../components/img/ImageGallery.jsx'
import ResizableImage from '../components/img/ResizableImage.jsx'
import img1 from '../assets/img/zephyr/Zephyr-1.png'
import img2 from '../assets/img/zephyr/Zephyr-2.png'


function Zephyr() {
  return (
    <div>
      <div style={{ backgroundColor: "#FFFBF2" }}>
        <TopGap />
        <PaddingContainer >
        <ColumnContainer >
            <GroupContainer>
                <TitleContainer variant="black">L'agence</TitleContainer>
            </GroupContainer>
            <GroupContainer>
                <TextContainer variant="black">Notre mission</TextContainer>
                <Gap variant="25px"/>
                <TextContainer variant="pblack">Transformer les visions en succès numérique. Nous collaborons avec passion avec nos clients pour concevoir des solutions sur mesure, de la conception Web à la stratégie de marketing numérique.</TextContainer>
                <Gap variant="40px"/>
                <TextContainer variant="black">Notre approche</TextContainer>
                <Gap variant="25px"/>
                <TextContainer variant="pblack">L’innovation est au cœur de tout ce que nous faisons. Nous adoptons les dernières technologies et les meilleures pratiques pour propulser nos clients vers de nouveaux sommets dans le monde numérique.</TextContainer>
             </GroupContainer>    
            <GroupContainer>
                <TextContainer variant="black">Pourquoi nous choisir?</TextContainer>
                <Gap variant="25px"/>
                <TextContainer variant="pblack">Chez Harmonie, nous sommes bien plus qu’une agence numérique. Nous sommes votre partenaire de confiance dans le monde numérique. Notre équipe dédiée met son expertise au service de chaque projet, garantissant des résultats mesurables et une approche collaborative. Que vous soyez une start-up ambitieuse, une PME en croissance ou une entreprise établie, notre engagement envers votre réussite numérique reste constant. Découvrez comment Harmonie peut concrétiser vos ambitions numériques.</TextContainer>
                <Gap variant="40px"/>
                <Link to="/contact"><Button variant="black">Contact</Button></Link>
            </GroupContainer>
        </ColumnContainer>
      </PaddingContainer>
      <PaddingContainer>
        <ColumnContainer2>
          <OneThirdContainer>
            <TitleContainer variant="green">Start-up </TitleContainer>
          </OneThirdContainer>
          <TwoThirdsContainer>
            <TextContainer variant="green">Conception pour Start-up</TextContainer>
            <Gap variant="25px" />
            <TextContainer variant="pgreen">Chez Harmonie Agency, nous comprenons l'importance d'une identité visuelle forte pour les start-up en plein essor. Notre service de contribution au design offre aux jeunes entreprises la possibilité de créer une marque mémorable qui attire l'attention et génère de l'engagement.</TextContainer>
            <Gap variant="25px" />
            <TextContainer variant="green">Notre Approche</TextContainer>
            <Gap variant="25px" />
            <TextContainer variant="pgreen">Nous travaillons en étroite collaboration avec les fondateurs pour comprendre leur vision et leurs objectifs commerciaux. Ensuite, nous créons une identité visuelle qui reflète leur mission et leur personnalité. Notre équipe de designers talentueux est là pour vous aider à créer une marque qui se démarque de la concurrence et qui attire votre public cible.</TextContainer>
            <ul>
              <li>
                <TextContainer variant="pgreen">Consultation Personnalisée : Nous débutons par une consultation approfondie pour comprendre les valeurs, la vision et les objectifs de votre start-up. Nous croyons en une approche personnalisée pour chaque client, afin de garantir que nos services de design répondent parfaitement à vos besoins spécifiques.</TextContainer>
              </li>
              <Gap variant="15px" />
              <li>
                <TextContainer variant="pgreen">Création d'Identité Visuelle : Notre équipe de designers talentueux travaille en étroite collaboration avec vous pour créer une identité visuelle distinctive qui capture l'essence de votre entreprise. Nous concevons des logos, des palettes de couleurs et des éléments graphiques qui reflètent votre marque et attirent votre public cible.</TextContainer>
              </li>
              <Gap variant="15px" />
              <li>
                <TextContainer variant="pgreen">Design de Produits et Services : En plus de l'identité visuelle, nous offrons également des services de design de produits et de services. Que vous ayez besoin de concevoir une application mobile, un site web, ou d'autres supports numériques, nous sommes là pour vous aider à créer des expériences utilisateur exceptionnelles.</TextContainer>
              </li>
              <Gap variant="15px" />
              <li>
                <TextContainer variant="pgreen">Test et Itérations : Nous croyons en un processus itératif de conception qui implique des tests et des ajustements continus pour garantir des résultats optimaux. Nous travaillons en étroite collaboration avec vous pour recueillir des retours d'information et apporter les modifications nécessaires tout au long du processus.</TextContainer>
              </li>
              <Gap variant="15px" />
              <li>
                <TextContainer variant="pgreen">Partenariat à Long Terme : Notre objectif est de devenir un partenaire de confiance pour votre start-up à mesure qu'elle se développe. Nous sommes là pour soutenir votre croissance en vous fournissant des services de design de qualité et des conseils stratégiques tout au long de votre parcours.</TextContainer>
              </li>
            </ul>
          </TwoThirdsContainer>
        </ColumnContainer2> 
      </PaddingContainer>
      </div>
      <div style={{ backgroundColor: "#000" }}>
        <PaddingContainer>
          <GroupContainer>
            <ColumnContainer>
              <GroupContainer>
                <TitleContainer variant="white">Logo</TitleContainer>
              </GroupContainer>
              <GroupContainer>
                <TextContainer variant="white">Partenariat à Long Terme : </TextContainer>
                <ResizableImage src={img1}/>
              </GroupContainer>
              <GroupContainer>
                <TextContainer variant="white">Partenariat à Long Terme : </TextContainer>
                <ResizableImage src={img1}/>
              </GroupContainer>
            </ColumnContainer>
          </GroupContainer>
          <ColumnContainer>
            <GroupContainer>
              <TitleContainer variant="white">Projet </TitleContainer>
              <ColumnContainer>
                <ColumnContainer3>
                  <OneContainer>
                    <TextContainer variant="white">Prêt à </TextContainer>
                  </OneContainer>
                  <TwoContainer>
                    <TextContainer variant="pwhite">Prêt à donner vie à votre vision ?</TextContainer>
                  </TwoContainer>
                </ColumnContainer3>
              </ColumnContainer>
            </GroupContainer>
          </ColumnContainer>

        </PaddingContainer>
      </div>
      <div style={{ backgroundColor: "#FFFBF2" }}>
      <PaddingContainer>
        <ColumnContainer> 
        <GroupContainer>
          <ResizableImage src={img1}/>
          <Gap variant="50px"/>
          <TextContainer variant="green">Test</TextContainer>
          <Gap variant="25px"/>
          <TextContainer variant="pgreen">Partenariat à Long Terme : Notre objectif est de devenir un partenaire de confiance pour votre start-up à mesure qu'elle se développe. Nous sommes là pour soutenir votre croissance en vous fournissant des services de design de qualité et des conseils stratégiques tout au long de votre parcours</TextContainer>
        </GroupContainer>
        <GroupContainer>
          <ResizableImage src={img1}/>
          <Gap variant="50px"/>
          <TextContainer variant="green">Test</TextContainer>
          <Gap variant="25px"/>
          <TextContainer variant="pgreen">Partenariat à Long Terme : Notre objectif est de devenir un partenaire de confiance pour votre start-up à mesure qu'elle se développe. Nous sommes là pour soutenir votre croissance en vous fournissant des services de design de qualité et des conseils stratégiques tout au long de votre parcours</TextContainer>
        </GroupContainer>
        <GroupContainer>
          <ResizableImage src={img1}/>
          <Gap variant="50px"/>
          <TextContainer variant="green">Test</TextContainer>
          <Gap variant="25px"/>
          <TextContainer variant="pgreen">Partenariat à Long Terme : Notre objectif est de devenir un partenaire de confiance pour votre start-up à mesure qu'elle se développe. Nous sommes là pour soutenir votre croissance en vous fournissant des services de design de qualité et des conseils stratégiques tout au long de votre parcours</TextContainer>
        </GroupContainer>
        </ColumnContainer>
      </PaddingContainer>
      <PaddingContainer>
        <ColumnContainer>
          <GroupContainer>
            <TitleContainer variant="green">Test projet</TitleContainer>
          </GroupContainer>
          <GroupContainer>

          </GroupContainer>
          <GroupContainer>
          <TextContainer variant="pgreen">Contactez-nous dès aujourd'hui pour commencer à travailler sur le design de votre start-up et créer une marque qui se démarque.</TextContainer>
          </GroupContainer>
        </ColumnContainer>
        <Gap variant="50px" />
        <ImageGallery imagePaths={[img1, img2, img2, img2, img2]} />
      </PaddingContainer>
      <PaddingContainer>
        <ColumnContainer2> 
        <OneThirdContainer>
          <TitleContainer variant="green">Contact</TitleContainer>
        </OneThirdContainer>
        <TwoThirdsContainer>
        <TextContainer variant="green">Prêt à donner vie à votre vision ?</TextContainer>
          <Gap variant="15px" />
          <TextContainer variant="pgreen">Contactez-nous dès aujourd'hui pour commencer à travailler sur le design de votre start-up et créer une marque qui se démarque.</TextContainer>
          <Gap variant="50px" />
          <Link to="/contact"><Button variant={"green"}>Contact</Button></Link>
        </TwoThirdsContainer>
        </ColumnContainer2> 
      </PaddingContainer>
      </div>
    </div>
  )
}

export default Zephyr;
