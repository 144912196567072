import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  max-width: 1200px;
  margin: 0 auto; 
  flex-direction: none;

  /* Media queries for small devices (phones) */
  @media screen and (max-width: 479px) {
    gap: 20px;
    flex-direction: column;
  }

  /* Media queries for medium devices (tablets) */
  @media screen and (min-width: 480px) and (max-width: 767px) {
    gap: 2Opx;
    flex-direction: column;
  }


  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 30px;
  }
  
  /* Media queries for medium devices (landscape tablets) */
  @media screen and (min-width: 480px) and (max-width: 767px) {
    flex-direction: column;
    gap: 30px;
  }
  
  
  /* Media queries for tablets */
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    flex-direction: column;
    gap: 40px;
  }
`;

const ColumnContainer = ({ children }) => {
  return <Container>{children}</Container>;
}

export default ColumnContainer;



