import React from 'react'
import Gap from '../components/Gap.jsx'
import PaddingContainer from '../components/Container/PaddingContainer.jsx'
import TextContainer from '../components/Container/TextContainer.jsx'
import TopGap from '../components/TopGap.jsx'
import TitleContainer from '../components/Container/TitleContainer.jsx'

function Mentionslégales() {
  return (
    <div style={{ backgroundColor: "#FFFBF2" }}>
      <PaddingContainer>
      <TopGap variant="100px"/>
      <TitleContainer variant="green">Mentions Légales</TitleContainer>
      <Gap variant="50px"/>
      <TextContainer variant="green">Éditeur du Site</TextContainer>
      <Gap variant="15px"/>
      <TextContainer variant="pgreen">Harmonie Agency SASU<br />
      Dénomination sociale : Harmonie Agency SASU <br />
      Forme juridique : Société par Actions Simplifiée Unipersonnelle (SASU) <br />
      Adresse du siège social : 61 rue de Lyon, 75012 Paris <br />
      Numéro SIRET : 983772120 <br />
      Numéro de TVA intracommunautaire : FR 95983772120 <br />
      Nom du Directeur de la Publication : David Vaugein <br />
      Adresse e-mail de contact : harmonieagency@outlook.com <br /></TextContainer>
      <Gap variant="50px"/>
      <TextContainer variant="green">Hébergement</TextContainer>
      <Gap variant="15px"/>
      <TextContainer variant="pgreen">Le site web de Harmonie Agency SASU est hébergé par Infomaniak, une société enregistrée en Suisse. <br />
      Raison sociale : Infomaniak Network SA<br />
      Adresse : Rue Eugène Marziano 25, 1127 Les Acacias, Genève, Suisse <br />
      Téléphone : +41228203541 <br />
      Site : http://www.infomaniak.com</TextContainer>
      <Gap variant="50px"/>
      <TextContainer variant="green">Propriété Intellectuelle</TextContainer>
      <Gap variant="15px"/>
      <TextContainer variant="pgreen">Le contenu de ce site web, y compris mais sans s'y limiter, les textes, les images, les vidéos, les graphiques, les logos, les marques de commerce et les éléments audiovisuels, est la propriété exclusive de Harmonie Agency SASU ou de ses fournisseurs de contenu, et est protégé par les lois sur la propriété intellectuelle en vigueur. Toute reproduction, distribution, modification ou utilisation du contenu sans autorisation préalable est strictement interdite.</TextContainer>
      <Gap variant="50px"/>
      <TextContainer variant="green">Liens Externes</TextContainer>
      <Gap variant="15px"/>
      <TextContainer variant="pgreen">Ce site peut contenir des liens vers des sites web tiers sur lesquels Harmonie Agency SASU n'a aucun contrôle. Nous déclinons toute responsabilité quant au contenu de ces sites web tiers et aux pratiques en matière de confidentialité. L'utilisation de ces liens est à vos propres risques.
      Limitation de Responsabilité Harmonie Agency SASU s'efforce de fournir des informations exactes et à jour sur son site web, mais ne garantit pas l'exactitude, l'exhaustivité ou la pertinence de ces informations. En conséquence, Harmonie Agency SASU décline toute responsabilité quant aux dommages directs ou indirects résultant de l'utilisation de ce site web ou de son contenu. En outre, Harmonie Agency SASU se réserve le droit de modifier, supprimer ou ajouter du contenu à ce site web à tout moment et sans préavis.</TextContainer>
      <Gap variant="50px"/>
      <TextContainer variant="green">Droit Applicable et Juridiction Compétente</TextContainer>
      <Gap variant="15px"/>
      <TextContainer variant="pgreen">Tout litige relatif à l'utilisation de ce site web sera régi par le droit français. En cas de litige, les tribunaux français seront seuls compétents.
      Contact Pour toute question ou réclamation concernant ce site web, veuillez nous contacter à l'adresse e-mail suivante : Harmonieagency@outlook.com.</TextContainer>
      </PaddingContainer>
    </div>
  )
}

export default Mentionslégales
