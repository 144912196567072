import React from 'react';

const ImageGallery = ({ imagePaths }) => {
  const imageContainerStyle = {
    maxWidth: '1200px',
    height: '600px', // Hauteur fixe des images
    whiteSpace: 'nowrap',
    overflowX: 'auto', // Défilement horizontal activé
    margin: '0 auto',
    overflow: '-moz-scrollbars-none', // Pour Firefox, pour masquer la barre de défilement
    '-ms-overflow-style': 'none', // Pour IE et Edge, pour masquer la barre de défilement
  };
  

  const imageScrollStyle = {
    display: 'flex', // Utilisation de flexbox pour aligner les images horizontalement
    gap: '15px', // Ecart entre les images
    paddingRight: '15px', // Pour compenser l'écart à droite
  };

  const imageStyle = {
    maxWidth: '1200px', // Largeur maximale des images
    height: '600px', // Hauteur fixe des images
    objectFit: 'cover', // Ajuste la taille de l'image tout en conservant les proportions et en remplissant le conteneur
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      <div style={imageContainerStyle}>
        <div style={imageScrollStyle}>
          {imagePaths.map((path, index) => (
            <img key={index} src={path} alt={`Image ${index + 1}`} style={imageStyle} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ImageGallery;
