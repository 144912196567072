import React, { useRef, useEffect, useCallback } from 'react';
import { gsap, ScrollTrigger } from 'gsap/all';
import styled from 'styled-components';

const MainContainer = styled.main`
  display: flex;
  position: relative;
  height: 230px;
  overflow-x: hidden;
  overflow-y: hidden; /* Désactive le défilement vertical */
  z-index: 1;
  align-items: center;
  max-width: 100vw;

  /* Media queries for small devices (portrait phones) */
  @media screen and (max-width: 479px) {
    font-size: 120px;
  }

  /* Media queries for responsiveness */
  @media screen and (max-width: 767px) {
    height: 120px;
  }

  /* Media queries for medium devices (landscape tablets) */
  @media screen and (min-width: 480px) and (max-width: 767px) {
    height: 160px;
  }

  /* Media queries for tablets */
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    height: 170px;
  }

  /* Media queries for large screens */
  @media screen and (min-width: 1024px) and (max-width: 1199px) {
    height: 230px;
  }

  /* Media queries for extra large devices (desktops) */
  @media screen and (min-width: 1200px) and (max-width: 1599px) {
    height: 230px;
  }

  /* Media queries for extra extra large devices (large desktops) */
  @media screen and (min-width: 1600px) and (max-width: 1999px) {
    height: 230px;
  }

  /* Media queries for extra extra large devices (extra large desktops) */
  @media screen and (min-width: 2000px) {
    height: 350px;
  }
`;

const SliderContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  align-items: center;
`;

const Slider = styled.div`
  position: relative;
  white-space: nowrap;
  display: flex;
`;

const Text = styled.div`
  position: relative;
  margin: 0;
  color: #092115;
  font-family: Hedvig Letters Serif;
  text-align: center;
  font-size: 200px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px; 
  text-transform: uppercase;
  user-select: none;

  /* Media queries for small devices (portrait phones) */
  @media screen and (max-width: 479px) {
    font-size: 100px;
  }

  /* Media queries for responsiveness */
  @media screen and (max-width: 767px) {
    font-size: 100px;
  }

  /* Media queries for medium devices (landscape tablets) */
  @media screen and (min-width: 480px) and (max-width: 767px) {
    font-size: 150px;
  }

  /* Media queries for tablets */
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: 150px;
  }

  /* Media queries for large screens */
  @media screen and (min-width: 1024px) and (max-width: 1199px) {
    font-size: 200px;
  }

  /* Media queries for extra large devices (desktops) */
  @media screen and (min-width: 1200px) and (max-width: 1599px) {
    font-size: 200px;
  }

  /* Media queries for extra extra large devices (large desktops) */
  @media screen and (min-width: 1600px) and (max-width: 1999px) {
    font-size: 200px;
  }

  /* Media queries for extra extra large devices (extra large desktops) */
  @media screen and (min-width: 2000px) {
    font-size: 300px;
  }
`;

export default function Textanim() {
  const firstText = useRef(null);
  const secondText = useRef(null);
  const slider = useRef(null);
  const lastTime = useRef(0); // Utilisez useRef pour conserver la valeur entre les rendus
  const xPercent = useRef(-64); // Utilisez useRef pour conserver la valeur entre les rendus
  const direction = useRef(-1); // Utilisez useRef pour conserver la valeur entre les rendus
  const targetFPS = 60; // Nombre d'images par seconde cible

  const animate = useCallback((currentTime) => {
    // Calculer le delta de temps en secondes
    const deltaTime = (currentTime - lastTime.current) / 1000;
    lastTime.current = currentTime; // Utilisez .current pour accéder à la valeur actuelle

    // Limiter la vitesse à 60 IPS
    const speed = 0.1; // Vitesse par frame
    const maxSpeed = speed * targetFPS; // Vitesse maximale

    // Calculer le déplacement en fonction du delta de temps
    const movement = maxSpeed * deltaTime;

    // Mettre à jour la position
    xPercent.current += movement * direction.current;

    if (xPercent.current < -100) {
      xPercent.current = 0;
    } else if (xPercent.current > 0) {
      xPercent.current = -100;
    }

    // Appliquer la transformation
    gsap.set(firstText.current, { xPercent: xPercent.current, ease: 'power1.inOut' });
    gsap.set(secondText.current, { xPercent: xPercent.current, ease: 'power1.inOut' });

    // Planifier la prochaine trame d'animation
    requestAnimationFrame(animate);
  }, [direction]); // Incluez 'direction' dans le tableau des dépendances

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to(slider.current, {
      scrollTrigger: {
        trigger: document.documentElement,
        scrub: 0.25,
        start: 0,
      },
    });
    requestAnimationFrame(animate);
  }, [animate]);
  
  return (
    <MainContainer>
      <SliderContainer>
        <Slider ref={slider}>
          <Text ref={firstText}>Create your own unique footprint -&nbsp;</Text>
          <Text ref={secondText} style={{ position: 'absolute', left: '100%', top: '0' }}>
            Create your own unique footprint -&nbsp;
          </Text>
        </Slider>
      </SliderContainer>
    </MainContainer>
  );
}
