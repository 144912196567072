import React, { useState, useEffect } from "react";
import Gap from "../components/Gap.jsx";
import PaddingContainer from "../components/Container/PaddingContainer.jsx";
import GroupContainer from "../components/Container/GroupContainer.jsx";
import ColumnContainer from "../components/Container/ColumnContainer.jsx";
import { ColumnContainer2, OneThirdContainer, TwoThirdsContainer } from '../components/Container/ColumnContainer2.jsx';
import TitleContainer from "../components/Container/TitleContainer.jsx";
import TextContainer from "../components/Container/TextContainer.jsx";
import Button from "../components/Button.jsx";
import InputForms from "../components/InputForm.jsx";
import TopGap from "../components/TopGap.jsx";


function Freelance() {
    const [name, setName] = useState(localStorage.getItem('name') || '');
    const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem('phoneNumber') || '');
    const [portfolio, setPortfolio] = useState(localStorage.getItem('portfolio') || '');
    const [email, setEmail] = useState(localStorage.getItem('email') || '');
    const [country, setCountry] = useState(localStorage.getItem('country') || '');
    const [validationError, setValidationError] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
  
    useEffect(() => {
      localStorage.setItem('name', name);
      localStorage.setItem('phoneNumber', phoneNumber);
      localStorage.setItem('portfolio', portfolio);
      localStorage.setItem('email', email);
      localStorage.setItem('country', country);


    }, [name, phoneNumber, portfolio, email, country]);
  
  
    const handleButtonClick = () => {
        console.log('Button clicked');
      
        if (!name || !phoneNumber || !portfolio || !email || !country) {
          // Validation Error
          setValidationError(true);
          setTimeout(() => {
            setValidationError(false);
          }, 3000);
        } else {
          // Soumettre le formulaire
          setFormSubmitted(true);
      
          // Réinitialiser les champs après soumission réussie
          setName('');
          setPhoneNumber('');
          setPortfolio('');
          setEmail('');
          setCountry('');
      
          // Supprimer les données locales
          localStorage.removeItem('name');
          localStorage.removeItem('phoneNumber');
          localStorage.removeItem('portfolio');
          localStorage.removeItem('email');
          localStorage.removeItem('country');
        }
      };

  return (
    <div style={{ backgroundColor: "#FFFBF2" }}>
        <TopGap/>
        <PaddingContainer>
            <ColumnContainer2 variant="one-third-two-thirds">
                <OneThirdContainer>
                    <GroupContainer>
                        <TitleContainer variant="green">Freelance</TitleContainer>
                    </GroupContainer>
                </OneThirdContainer>
                <TwoThirdsContainer>
                    <GroupContainer>
                        <TextContainer variant="green">Rejoignez notre Liste de Freelancers</TextContainer>
                        <Gap variant="25px" />
                        <TextContainer variant="pgreen">Chez Harmonie Agency, nous sommes toujours à la recherche de talents exceptionnels pour collaborer sur des projets stimulants et innovants. Si vous êtes un freelance passionné par votre métier et désireux de rejoindre des projets dynamique, vous êtes au bon endroit.</TextContainer>
                        <Gap variant="15px" />
                        <TextContainer variant="pgreen">Nous sommes constamment à la recherche de créatifs, de développeurs, de rédacteurs, de marketeurs et d'autres professionnels du digital pour enrichir notre réseau de freelancers. </TextContainer>
                        <Gap variant="15px" />
                        <TextContainer variant="pgreen">Pourquoi rejoindre notre réseaux de freelancers ?</TextContainer>
                        <ul>
                        <li>
                            <TextContainer variant="pgreen">Flexibilité et liberté : En travaillant en tant que freelance avec nous, vous bénéficiez de la flexibilité de choisir les projets qui correspondent le mieux à votre expertise et à votre emploi du temps, vous permettant ainsi de concilier travail et vie personnelle de manière optimale.</TextContainer>
                        </li>
                        <Gap variant="15px" />
                        <li>
                            <TextContainer variant="pgreen">Collaboration transparente : Nous croyons en une collaboration transparente et respectueuse entre freelancers et clients. Notre équipe est là pour faciliter la communication, clarifier les attentes et garantir une expérience de travail harmonieuse pour toutes les parties impliquées.</TextContainer>
                        </li>
                        </ul>
                        <Gap variant="15px" />
                        <TextContainer variant="pgreen">Prêt à rejoindre notre liste de freelance et à relever de nouveaux défis passionnants ? Remplissez le formulaire et déposer un lien vers votre portfolio.</TextContainer>
                        <Gap variant="15px" />
                        <TextContainer variant="pseo">Freelancers, Freelance, Collaborateurs indépendants, Projets digitaux, Portfolio freelance, Opportunités de collaboration, Compétences numériques, Talent numérique, Flexibilité professionnelle, Métiers du digital, Développeurs web, Designers graphiques, Rédacteurs créatifs</TextContainer>
                        <Gap variant="50px" />
                        <Gap variant="50px" />
                        <GroupContainer>
                            <ColumnContainer>
                                <GroupContainer>
                                    <InputForms
                                        variant="green"
                                        label="Name"
                                        id="name"
                                        placeholder="Nom"
                                        type="Nom"
                                        isRequired
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className={!name && validationError ? 'error' : ''}
                                        style={!name && validationError ? { backgroundColor: 'rgba(255, 0, 0, 0.3)', animation: `shake 0.5s` } : {}}
                                    />
                                    <Gap variant="50px" />
                                    <InputForms
                                        variant="green"
                                        label="Phone-number"
                                        id="Phone-number"
                                        placeholder="Téléphone"
                                        type="Téléphone"
                                        isRequired
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        className={!phoneNumber && validationError ? 'error' : ''}
                                        style={!phoneNumber && validationError ? { backgroundColor: 'rgba(255, 0, 0, 0.3)', animation: `shake 0.5s` } : {}}
                                    />
                                    </GroupContainer>
                                    <GroupContainer>
                                    <InputForms
                                        variant="green"
                                        label="Email"
                                        id="Email"
                                        placeholder="Email"
                                        type="Email"
                                        isRequired
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className={!email && validationError ? 'error' : ''}
                                        style={!email && validationError ? { backgroundColor: 'rgba(255, 0, 0, 0.3)', animation: `shake 0.5s` } : {}}
                                    />
                                    <Gap variant="50px" />
                                    <InputForms
                                        variant="green"
                                        label="Country"
                                        id="Country"
                                        placeholder="Pays"
                                        type="Pays"
                                        isRequired
                                        value={country}
                                        onChange={(e) => setCountry(e.target.value)}
                                        className={!country && validationError ? 'error' : ''}
                                        style={!country && validationError ? { backgroundColor: 'rgba(255, 0, 0, 0.3)', animation: `shake 0.5s` } : {}}
                                    />
                                </GroupContainer>
                            </ColumnContainer>
                            <Gap variant="50px" />
                            <InputForms
                                        variant="green"
                                        label="Portfolio"
                                        id="Portfolio"
                                        placeholder="Lien portfolio"
                                        type="Porfolio"
                                        isRequired
                                        value={portfolio}
                                        onChange={(e) => setPortfolio(e.target.value)}
                                        className={!portfolio && validationError ? 'error' : ''}
                                        style={!portfolio && validationError ? { backgroundColor: 'rgba(255, 0, 0, 0.3)', animation: `shake 0.5s` } : {}}
                                    />
                            <Gap variant="50px" />
                            <Gap variant="50px" />
                            <Button variant="green" onClick={handleButtonClick}>Submit</Button>
                            {validationError && (
                            <div>
                                <Gap variant="25px" />
                                <p className="error-text" style={{ color: 'red' }}>Veuillez remplir tous les champs obligatoires</p>
                            </div>
                            )}
                            {formSubmitted && (
                            <div>
                                <Gap variant="25px" />
                                <p className="success-text" style={{ color: '#092115' }}>Formulaire envoyé avec succès</p>
                            </div>
                            )}
                        </GroupContainer>
                    </GroupContainer>
                </TwoThirdsContainer>
            </ColumnContainer2>
        </PaddingContainer>
        <style>{`
        @keyframes shake {
          0% { transform: translateX(0); }
          25% { transform: translateX(-5px); }
          50% { transform: translateX(5px); }
          75% { transform: translateX(-5px); }
          100% { transform: translateX(0); }
        }
      `}</style>
    </div>
  );
}
export default Freelance;