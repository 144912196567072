import React from "react";
import styled from "styled-components";

const TopGapComponent = styled.div`
    position: relative;
    width: 100%;
    background-color: rose;
    height: 75px;

    /* Media queries for small devices (portrait phones) */
    @media screen and (max-width: 479px) {
        height: 75px;
    }

    /* Media queries for medium devices (landscape tablets) */
    @media screen and (min-width: 480px) and (max-width: 767px) {
        height: 80px;
    }
    
    /* Media queries for tablets */
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        height: 80px;
    }
    
    /* Media queries for large screens */
    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        height: 80px;
    }
    
    /* Media queries for extra large screens */
    @media screen and (min-width: 1200px) {
        height: height: 80px;
    }
`;

const TopGap = ({ type, variant, className, id, children }) => {

  return (
    <TopGapComponent
    type={type ? type : "gap"} 
    variant={variant} 
    className={className ? `top-gap-component ${className}`: "top-gap-component"}
    id={id} 
    >
    {children}
    </TopGapComponent>
  );
}

export default TopGap;