import React from "react";
import styled from "styled-components";

const TagsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Espace entre chaque tag */
`;

const TagComponent = styled.div`
    width: max-content;
    background-color: transparent;
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 6.5px;
    padding-bottom: 6.5px;
    flex-shrink: 0;
    border-radius: 100px;
    border: 1px solid #092115; 
    display: flex;  
    justify-content: center;
    align-items: center;
    transition: background-color 0.6s ease, color 0.6s ease; 
    font-family: BD;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    
    &:hover {
        background-color: #DEFF00 !important;
        border: 1px solid #DEFF00;
    }
`;

const TagContainer = ({ children }) => {
  return (
    <TagsContainer>
      {children}
    </TagsContainer>
  );
}

const Tag = ({ children }) => {
  return (
    <TagComponent>
      {children}
    </TagComponent>
  );
}

export { TagContainer, Tag };
