import React, { useState, useRef, useEffect } from 'react';

const ImageHover = ({ images }) => {
  const [hovered, setHovered] = useState(false);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const maskRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMouseX(event.clientX);
      setMouseY(event.clientY);
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleChangeImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        width: '100%',
        height: '400px',
        overflow: 'hidden',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleChangeImage}
    >
      <img
        src={images[currentImageIndex]}
        alt="Image"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
      {hovered && (
        <div
          ref={maskRef}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            pointerEvents: 'none',
            zIndex: 999,
            clipPath: `circle(50px at ${((mouseX - containerRef.current.getBoundingClientRect().left) / containerRef.current.offsetWidth) * 100}% ${((mouseY - containerRef.current.getBoundingClientRect().top) / containerRef.current.offsetHeight) * 100}%)`,
          }}
        />
      )}
    </div>
  );
};

export default ImageHover;
