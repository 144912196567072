import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: relative;
    size: 100%;
    font-family: BD;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; 

    color: ${props =>
        props.variant === "green" ? "#092115":  
        props.variant === "white" ? "#FFFBF2": 
        props.variant === "pgreen" ? "#092115":  
        props.variant === "pwhite" ? "#FFFBF2": 
        props.variant === "pseo" ? "#BBBBBB": 
        props.variant === "black" ? "#000000":
        props.variant === "pblack" ? "#000000":
        "transparent"};

    ${props => 
        (props.variant === "white" || props.variant === "green" || props.variant === "black") && `
            font-family: Amiamie;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; 
            text-transform: capitalize;
        `}     
`;

const TextContainer = ({ type, variant, className, id,  children }) => {
  return (
    <Container

    type={type ? type : "text"} 
    variant={variant} 
    className={className ? `text-component ${className}`: "text-component"}
    id={id} 
    >
    {children}
    </Container> 
    );
}

export default TextContainer;
