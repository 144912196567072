import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px; /* Espace entre chaque composant */

  /* Media queries for small devices (portrait phones) */
  @media screen and (max-width: 479px) {
    flex-direction: column
  }

  /* Media queries for responsiveness */
  @media screen and (max-width: 767px) {
    flex-direction: column
  }
`;

const GroupGapContainer = ({ children }) => {
  return (
    <Container>
      {children}
    </Container>
  );
}

export default GroupGapContainer;
