import React from 'react'
import '../Styles/Headermain.css'
import Textanim from './Textanim.jsx'
import { Tag, TagContainer } from './Tag.jsx'
import GroupContainer from './Container/GroupContainer.jsx'
import ColumnContainer from './Container/ColumnContainer.jsx'
import Gap from './Gap.jsx';
import TextContainer from './Container/TextContainer.jsx'
import TitleContainer from './Container/TitleContainer.jsx'
import PaddingContainer from './Container/PaddingContainer.jsx'
import GroupGapContainer from './Container/GroupGapContainer.jsx'
import TextCenter from './TextCenter.jsx'
import MaxHeightHeadermain from './MaxHeightHeadermain.jsx'



function Headermain() {
  return (
    <div style={{ backgroundColor: "#FFFBF2" }}>
      <MaxHeightHeadermain>
      <GroupContainer>
        <PaddingContainer variant="top">
        <PaddingContainer variant="leftright">
          <ColumnContainer>
            <TitleContainer variant="green">HARMONIE</TitleContainer>
          </ColumnContainer>
        </PaddingContainer>
        </PaddingContainer>
      </GroupContainer>

      <Textanim/>
      <PaddingContainer variant="bottom">
      <PaddingContainer variant="leftright">
      <ColumnContainer>
        <GroupContainer>
          <GroupContainer>
            <ColumnContainer>
            <GroupContainer>
              <GroupGapContainer>
                <TextCenter>Agence</TextCenter>
                <TextContainer variant="pgreen">Bienvenue chez Harmonie, votre partenaire digital dédié à l’innovation et à la croissance. Nous sommes une agence digitale basée en France, spécialisée dans la création d’expériences en ligne exceptionnelles pour les startups et les entreprises en croissance.</TextContainer>
              </GroupGapContainer>
            </GroupContainer>
            <GroupContainer>
            </GroupContainer>
            </ColumnContainer>
          </GroupContainer>
          <Gap variant="25px" />
          <GroupContainer>
            <TagContainer>
              <Tag>Identité de marque</Tag>
              <Tag>UI / UX design</Tag>
              <Tag>Logo</Tag>
              <Tag>Communication</Tag>
              <Tag>SEO</Tag>
            </TagContainer>
          </GroupContainer>
        </GroupContainer>
      </ColumnContainer>
      </PaddingContainer>
      </PaddingContainer>
      </MaxHeightHeadermain>
    </div>
  )
}

export default Headermain
