import React from 'react'
import Gap from '../components/Gap.jsx'
import PaddingContainer from '../components/Container/PaddingContainer.jsx'
import TextContainer from '../components/Container/TextContainer.jsx'
import TopGap from '../components/TopGap.jsx'
import TitleContainer from '../components/Container/TitleContainer.jsx'
import { ColumnContainer2, OneThirdContainer, TwoThirdsContainer } from '../components/Container/ColumnContainer2.jsx';
import Button from '../components/Button.jsx'
import { Link } from 'react-router-dom'
import ImageHover from '../components/ImageHover.jsx'
import GroupContainer  from '../components/Container/GroupContainer.jsx'
import ColumnContainer  from '../components/Container/ColumnContainer.jsx'
import img1 from '../assets/img/zephyr/Zephyr-1.png'

function Projets() {
  return (
    <div>
      <div style={{ backgroundColor: "#FFFBF2" }}>
        <TopGap />
        <PaddingContainer>
          <ColumnContainer2>
            <OneThirdContainer>
              <TitleContainer variant="green">Start-up </TitleContainer>
            </OneThirdContainer>
            <TwoThirdsContainer>
              <TextContainer variant="green">Conception pour Start-up</TextContainer>
              <Gap variant="25px" />
              <TextContainer variant="pgreen">Chez Harmonie Agency, nous comprenons l'importance d'une identité visuelle forte pour les start-up en plein essor. Notre service de contribution au design offre aux jeunes entreprises la possibilité de créer une marque mémorable qui attire l'attention et génère de l'engagement.</TextContainer>
              <Gap variant="50px" />
              <Gap variant="50px" />
              <Link to="/projets/zephyr">
              <ImageHover images={[img1]}/>
              </Link>
          </TwoThirdsContainer>
        </ColumnContainer2> 
      </PaddingContainer>
      </div>
      <div style={{ backgroundColor: "#FFFBF2" }}>
      <PaddingContainer>
        <ColumnContainer2> 
        <OneThirdContainer>
          <TitleContainer variant="green">Contact</TitleContainer>
        </OneThirdContainer>
        <TwoThirdsContainer>
        <TextContainer variant="green">Prêt à donner vie à votre vision ?</TextContainer>
              <Gap variant="15px" />
              <TextContainer variant="pgreen">Contactez-nous dès aujourd'hui pour commencer à travailler sur le design de votre start-up et créer une marque qui se démarque.</TextContainer>
              <Gap variant="50px" />
              <Link to="/contact"><Button variant={"green"}>Contact</Button></Link>
        </TwoThirdsContainer>
        </ColumnContainer2> 
      </PaddingContainer>
      </div>
    </div>
  )
}

export default Projets;
