import React from "react";
import styled from "styled-components";

const GapComponent = styled.div`
    position: relative;
    width: 100%;
    background-color: rose;
    height: ${props =>
        props.variant === "25px" ? "15px": 
        props.variant === "15px" ? "10px": 
        props.variant === "40px" ? "30px" : 
        props.variant === "50px" ? "40px" :
        "50px"}; // Valeur par défaut pour le maximum

    /* Media queries for small devices (portrait phones) */
    @media screen and (max-width: 479px) {
        height: ${props =>
            props.variant === "25px" ? "15px": 
            props.variant === "15px" ? "10px": 
            props.variant === "40px" ? "30px": 
            props.variant === "50px" ? "40px": 
            "30px"}; // Valeur par défaut pour le maximum
    }

    /* Media queries for medium devices (landscape tablets) */
    @media screen and (min-width: 480px) and (max-width: 767px) {
        height: ${props =>
            props.variant === "25px" ? "20px": 
            props.variant === "15px" ? "10px": 
            props.variant === "40px" ? "30px": 
            props.variant === "50px" ? "40px": 
            "35px"}; // Valeur par défaut pour le maximum
    }
    
    /* Media queries for tablets */
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        height: ${props =>
            props.variant === "25px" ? "20px": 
            props.variant === "15px" ? "10px": 
            props.variant === "40px" ? "35px": 
            props.variant === "50px" ? "45px": 
            "45px"}; // Valeur par défaut pour le maximum
    }
    
    /* Media queries for large screens */
    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        height: ${props =>
            props.variant === "25px" ? "20px": 
            props.variant === "15px" ? "15px": 
            props.variant === "40px" ? "40px": 
            props.variant === "50px" ? "50px":
            "50px"}; // Valeur par défaut pour le maximum
    }
    
    /* Media queries for extra large screens */
    @media screen and (min-width: 1200px) {
        height: ${props =>
            props.variant === "25px" ? "25px": 
            props.variant === "15px" ? "15px": 
            props.variant === "40px" ? "40px": 
            props.variant === "50px" ? "50px":
            "55px"}; // Valeur par défaut pour le maximum
    }
`;

const Gap = ({ type, variant, className, id, children }) => {

  return (
    <GapComponent
    type={type ? type : "gap"} 
    variant={variant} 
    className={className ? `gap-component ${className}`: "gap-component"}
    id={id} 
    >
    {children}
    </GapComponent>
  );
}

export default Gap;