import React from "react";
import styled from "styled-components";

const ButtonComponent = styled.button`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    border-radius: 0;
    padding: 8px;
    width: 100%;
    font-family: BD;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 1px solid ${props => 
        props.variant === "green-border" ? "#092115" : 
        props.variant === "white-border" ? "#FFFBF2" : 
        "transparent"};

    background-color: ${props =>
        props.variant === "green" ? "#092115": 
        props.variant === "white" ? "#FFFBF2": 
        props.variant === "green-border" ? "transparent" : 
            props.variant === "white-border" ? "transparent" :
        "transparent"};
    
    color: ${props =>
        props.variant === "green" ? "#FFFBF2": 
        props.variant === "white" ? "#092115": 
        props.variant === "green-border" ? "#092115" : 
        props.variant === "white-border" ? "#FFFBF2" :
        "transparent"};
    
    transition: all 0.3s ease; /* Ajoute un effet de transition smooth */

    &:hover {
        background-color: ${props =>
            props.variant === "green" ? "transparent":  /* Couleur de fond au survol */
            props.variant === "white" ? "transparent": 
            props.variant === "green-border" ? "#092115" : 
            props.variant === "white-border" ? "#FFFBF2" :
            "transparent"};
        color: ${props =>
            props.variant === "green" ? "#092115":  /* Couleur du texte au survol */
            props.variant === "white" ? "#FFFBF2": 
            props.variant === "green-border" ? "#FFFBF2" : 
            props.variant === "white-border" ? "#092115" :
            "transparent"};
         border: 1px solid ${props => 
            props.variant === "green" ? "#092115" : 
            props.variant === "white" ? "#FFFBF2" : 
            "transparent"};

`;

const Button = ({ type, variant, className, id, onClick, children }) => {

  return (
    <ButtonComponent
    type={type ? type : "button"} 
    variant={variant} 
    className={className ? `btn-component ${className}`: "btn-component"}
    id={id} 
    onClick={onClick}
    >
    {children}
    </ButtonComponent>
  );
}

export default Button;